import {
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS_BY_ROLE_FAIL,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  USER_CRITERIA_FAIL,
  USER_CRITERIA_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
  changePassSuccess: false,
  resetPassSuccess: false,
  updateSuccess: false
};

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_USER_SUCCESS:

      return {
        ...state,
        users: [...state.users, action.payload]
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload
      };

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        )
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.id.toString() !== action.req.toString()
        )
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        users: action.payload
      };

    case GET_USERS_BY_ROLE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        userProfile: action.payload
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        changePassSuccess: true
      };

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
        changePassSuccess: false
      };

    case USER_CRITERIA_SUCCESS:
      return {
        ...state,
        users: action.payload
      };

    case USER_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case RESET_PASSWORD:
      return {
        ...state,
        userProfile: action.payload
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        resetPassSuccess: true
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
        resetPassSuccess: false
      };

    default:
      return {
        ...state,
        changePassSuccess: false,
        resetPassSuccess: false,
        updateSuccess: false,
        error: {}
      };
  }
};

export default users;
