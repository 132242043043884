import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Input, Label, Row } from "reactstrap";

// Column
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { updatePatientAppointment } from "../../store/patientAppointment/actions";

const AppointmentDetail = props => {

  const dispatch = useDispatch();

  const [detail, setDetail] = useState(props.detailObject);
  const [doctor, setDoctor] = useState({});
  const [date, setDate] = useState({});
  const [time, setTime] = useState({});
  const [doctorOptions, setDoctorOptions] = useState([]);

  useEffect(() => {
    setDetail(props.detailObject);
  }, [props.detailObject]);

  useEffect(() => {
    if (props.detailObject && props.detailObject.appointmentDate) {
      setDate(props.detailObject.appointmentDate.split(" ")[0]);
      setTime(props.detailObject.appointmentDate.split(" ")[1]);
    }
  }, [props.detailObject]);

  useEffect(() => {
    if (props.detailObject && props.detailObject.appointmentUser) {
      setDoctor({ label: props.detailObject.appointmentUser.fullName, value: props.detailObject.appointmentUser });
    }
  }, [props.detailObject]);

  useEffect(() => {
    setDoctorOptions(props.doctorOptions);
  }, [props.doctorOptions]);

  const approveRejectAppt = (event) => {
    let apptDate = date + " " + time;
    if (time.length <= 5) {
      apptDate = date + " " + time + ":00";
    }

    const apptReq = {
      ...detail,
      status: event === "A" ? "APPROVED" : "REJECTED",
      appointmentDate: apptDate,
      appointmentUser: doctor.value
    };
    dispatch(updatePatientAppointment(apptReq));
  };

  function handleChangeNameAndPhone(e) {
    const updatedPatient = {
      ...detail.patient,
      [e.target.name]: e.target.value
    };
    setDetail({
      ...detail,
      ["patient"]: updatedPatient
    });
  }

  function onChangeDoctor(event) {
    setDoctor(event);
  }

  function handleChangeDate(e) {
    setDate(e.target.value);
  }

  function handleChangeTime(e) {
    setTime(e.target.value);
  }

  return (
    <React.Fragment>
      <Card>
        <div className="row mt-3">
          <Col className="col-12">
            <div className="mb-2">
              <Label className="form-label">{props.t("Name")}</Label>
              <Input
                id="fullName"
                name="fullName"
                type="text"
                onChange={handleChangeNameAndPhone}
                value={detail && detail.patient && detail.patient.fullName || ""}
              />
            </div>
            <div className="mb-2">
              <Label className="form-label">{props.t("Phone")}</Label>
              <Input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                onChange={handleChangeNameAndPhone}
                value={detail && detail.patient && detail.patient.phoneNumber || ""}
              />
            </div>
            <div className="mb-2">
              <Label className="form-label">{props.t("Specialist")}</Label>
              <Select
                value={doctor}
                onChange={(event) => onChangeDoctor(event)}
                options={doctorOptions}
                className="bs-select"
                isClearable={true}
              />
            </div>
            <div className="mb-2">
              <div className="row">
                <div className="col-6">
                  <Label className="form-label">{props.t("Date")}</Label>
                  <Input
                    id="appointmentDate"
                    name="date"
                    type="date"
                    onChange={handleChangeDate}
                    value={date}
                  />
                </div>
                <div className="col-6">
                  <Label className="form-label">{props.t("Time")}</Label>
                  <Input
                    id="time"
                    name="time"
                    type="time"
                    onChange={handleChangeTime}
                    value={time}
                  />
                </div>
              </div>
            </div>
          </Col>
        </div>
        <Row className="mt-3">
          <div className="row text-center">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-outline-success btn-lg ms-2"
                onClick={() => approveRejectAppt("A")}
              >
                {props.t("Approve")}
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="btn btn-outline-danger btn-lg ms-2"
                onClick={() => approveRejectAppt("R")}
              >
                {props.t("Reject")}
              </button>
            </div>
          </div>
        </Row>
      </Card>
    </React.Fragment>
  );
};
AppointmentDetail.propTypes = {
  t: PropTypes.any,
  detailObject: PropTypes.any,
  callback: PropTypes.func
};

export default withTranslation()(AppointmentDetail);
