import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  addNewUserWorkSchedule,
  getLanguages as onGetLanguages,
  getUserProfile,
  updateUser as onUpdateUser
} from "store/actions";
import { Phone } from "../../Leads/CustCol";
import { withTranslation } from "react-i18next";
import ActivityComp from "../../Dashboard/ActivityComp";
import WelcomeComp from "../../Dashboard/WelcomeComp";
import { fileToBase64, formatDate } from "../../../common/commonFunctions";
import Select from "react-select";
import Notification from "../../../components/Notification";
import Calendar from "../../../components/Calendar";
import { get, post } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";

const isAgent = process.env.REACT_APP_IS_AGENT === "TRUE";
const UsersProfile = props => {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  //meta title
  document.title = `${props.t("User Profile")}`;

  const dispatch = useDispatch();

  const { userProfile } = props;

  const now = new Date();
  const minus10day = new Date(now.getFullYear(),
    now.getMonth(),
    now.getDay() - 10).toISOString().split("T")[0];

  const plus2month = new Date(now.getFullYear(),
    now.getMonth() + 3,
    now.getDay()).toISOString().split("T")[0];

  const patientAppointmentBody = {
    size: 100,
    page: 0,
    appointmentUser: authUser && authUser.id || userProfile && userProfile.id,
    appointmentDateStart: minus10day,
    appointmentDateEnd: plus2month,
    status: "APPROVED"
  };

  const initalCont = {
    id: userProfile.id,
    username: userProfile.username,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    email: userProfile.email,
    phoneNumber: userProfile.phoneNumber,
    photo: userProfile.photo,
    languages: userProfile.languages
  };

  const [selectedMulti, setselectedMulti] = useState(null);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(initalCont);
  const [mailValid, setMailValid] = useState(false);
  const [mail, setMail] = useState(user && user.email || "");
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [events, setEvents] = useState([]);
  const [toggleCalendarModal, setToggleCalendarModal] = useState(false);
  const [patientAppointments, setPatientAppointments] = useState([]);
  const [eventDetail, setEventDetail] = useState({ userId: authUser && authUser.id });
  const [userAvailability, setUserAvailability] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (authUser && authUser.id) {
      dispatch(getUserProfile(authUser.id));
    }
  }, []);

  useEffect(() => {
    post(url.GET_PATIENT_APPOINTMENT_CRITERIA, patientAppointmentBody).then(response => setPatientAppointments(response.data.content));
  }, []);

  useEffect(() => {
    get(`${url.AVAILABILTY_API_BY_ID}/${authUser.id}`).then(response => setUserAvailability(response.data));
  }, []);

  const { error, updateSuccess } = useSelector(state => ({
    error: state.users.error,
    updateSuccess: state.users.updateSuccess
  }));

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const handleChangePhoto = (e) => {
    fileToBase64(e.target.files[0]).then(response => {
      setUser({
        ...user,
        ["photo"]: response
      });
    });
  };

  useEffect(() => {
    const langs = user.languages !== null && user.languages !== undefined ? user.languages.map(lang => ({
      label: lang.name,
      value: lang
    })) : null;
    setselectedMulti(langs);
  }, [user]);

  function handleMulti(selectedMulti) {
    const langCodes = selectedMulti.map((lang) => lang.value);
    setselectedMulti(selectedMulti);
    setUser({
      ...user,
      ["languages"]: langCodes
    });
  }

  const handleChangeMail = (e) => {
    const regEXpMail = /^\S+@\S+\.\S+$/;
    setMailValid(false);
    const mail = e.target.value.trim();
    setMail(mail);
    if (regEXpMail.test(mail)) {
      setMailValid(true);
      setUser({
        ...user,
        ["email"]: mail
      });
    }
  };

  const { languages } = useSelector(state => ({
    languages: state.Languages.languages
  }));

  const languageOptions = [];
  languages.map((item) => {
    languageOptions.push({ label: item.name, value: item });
  });

  useEffect(() => {
    dispatch(onGetLanguages());
  }, []);

  const saveUser = () => {
    const updated = dispatch(onUpdateUser(user));
    setUser(updated.payload);
  };

  const onRefresh = () => {
    dispatch(getUserProfile(authUser.id));
  };

  useEffect(() => {
    const newEvents = [];
    if (userAvailability) {
      userAvailability.forEach(item => {
        const isEventExist = item.id && events.some(event => event.id === item.id);
        if (!isEventExist || events.length === 0) {
          newEvents.push({
            id: item.id,
            type: "Leave",
            title: props.t("Leave"),
            start: new Date(item.startTime),
            end: new Date(item.endTime),
            className: "bg-secondary bg-soft text-white"
          });
        }
      });
    }

    if (patientAppointments) {
      patientAppointments.forEach(item => {
        const isEventExist = events.some(event => event.id === item.id);
        if (!isEventExist) {
          newEvents.push({
            id: item.id,
            title: item.patient.fullName || item.patient.name,
            description: item.description,
            user: item.appointmentUser,
            patient: item.patient,
            start: new Date(item.appointmentDate),
            end: new Date(item.appointmentDate),
            className: "bg-success bg-soft text-white"
          });
        }
      });
    }
    setEvents([...events, ...newEvents]);
  }, [userAvailability, patientAppointments]);

  const toggle = () => {
    if (modal) {
      setUser(initalCont);
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (Object.entries(error).length > 0) {
      setShowNotification(true);
      setMessage(error.message);
      setNotificationType("Danger");
    } else if (updateSuccess === true) {
      setShowNotification(true);
      setMessage(`${props.t("Success")}`);
      setNotificationType("Success");
    }
  }, [updateSuccess, dispatch, error]);

  function handleEventSave() {
    setIsButtonDisabled(true);
    const saveData = [{
      userId: authUser.id,
      startTime: startDate,
      endTime: endDate
    }];
    post(url.AVAILABILTY_API, saveData).then(response => {
      setToggleCalendarModal(!toggleCalendarModal);
      setUserAvailability([...userAvailability, ...response.data]);
    });
  }

  function callbackSelectedEvent(callbackEvent) {
    setStartDate(formatDate(callbackEvent.start));
    setEndDate(formatDate(callbackEvent.end));
    setEventDetail(callbackEvent);
  }

  function callbackSelectedDateStart(e) {
    setStartDate(e);
  }

  function callbackSelectedDateEnd(e) {
    setEndDate(e);
  }

  function saveChanges(callbackData) {
    const req = Object.assign({
      ...callbackData,
      userId: props.userProfile.id || authUser.id
    });
    dispatch(addNewUserWorkSchedule(req));
  }

  const eventModal = (
    <div id="toolbar" className="col-12 d-inline-block align-content-end">
      <div className="container">
        <Row>
          <Col className="col-12 mb-3">
            <Label className="form-label">{props.t("Start")}</Label>
            <Input
              name="start"
              type="datetime-local"
              onChange={callbackSelectedDateStart}
              value={startDate || ""}
            />
          </Col>
          <Col className="col-12 mb-3">
            <Label className="form-label">{props.t("End")}</Label>
            <Input
              name="end"
              type="datetime-local"
              onChange={callbackSelectedDateEnd}
              value={endDate || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={() => setToggleCalendarModal(!toggleCalendarModal)}
              >
                {props.t("Close")}
              </button>
              <button
                disabled={isButtonDisabled}
                type="submit"
                className="btn btn-success save-event"
                onClick={handleEventSave}
              >
                {props.t("Save selected days as days off")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("User Profile")} />
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />

          <Row>
            <Col xl={4}>
              <Card className="overflow-hidden">
                <WelcomeComp
                  viewProfileButton={false}
                />
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row className="m-2">
                      <Col xs={10}>
                        {props.t("Personal Information")}
                      </Col>
                      <Col xs={2}>
                        <Button
                          size="sm"
                          className="mb-2 me-0"
                          color="primary"
                          onClick={toggle}
                        >
                          {props.t("Edit")}
                        </Button>
                        {" "}
                        <Button
                          size="sm"
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-0"
                          onClick={onRefresh}
                        >
                          <i className="mdi mdi-refresh" />
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  {user && (
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                        <tr>
                          <th scope="row">{props.t("Username")}:</th>
                          <td>{userProfile.username}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Fullname")}:</th>
                          <td>{userProfile.fullName}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Phone")}:</th>
                          <td>{userProfile.phoneNumber}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("E-mail")}:</th>
                          <td>{userProfile.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Languages")}:</th>
                          <td>{userProfile.languages && userProfile.languages.map(item => `${item.name} ,`)}</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">{props.t("Edit")}</ModalHeader>
                <ModalBody>
                  <Row form="true">
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Username")}</Label>
                        <Input
                          name="username"
                          type="text"
                          onChange={handleChange}
                          value={user.username || ""}
                          invalid={!user.username}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Firstname")}</Label>
                        <Input
                          name="firstName"
                          label="İsim"
                          type="text"
                          onChange={handleChange}
                          value={user.firstName || ""}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Lastname")}</Label>
                        <Input
                          name="lastName"
                          label="Soyisim"
                          type="text"
                          onChange={handleChange}
                          value={user.lastName || ""}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Email")}</Label>
                        {!mailValid && (
                          <Alert color="warning" style={{ marginTop: "3px" }}>
                            {props.t("Please enter a valid email address. This email address will be used for password reset and notifications.")}
                          </Alert>
                        )}
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          onChange={handleChangeMail}
                          value={mail}
                          invalid={!user.email}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="control-label">
                          {props.t("Languages")}
                        </label>
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={(event) => {
                            handleMulti(event);
                          }}
                          placeholder={props.t("Select")}
                          options={languageOptions}
                          className="select2-selection"
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Photo")}</Label>
                        <Input
                          id="photo"
                          name="photo"
                          type="file"
                          placeholder={props.t("No file selected.")}
                          onChange={handleChangePhoto}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                          onClick={saveUser}
                        >
                          {props.t("Save")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
          {isAgent && (
            <Row>
              <Card>
                <CardBody>
                  <Calendar
                    external={true}
                    events={events}
                    t={props.t}
                    eventComponent={eventModal}
                    callbackSelectedDateStart={callbackSelectedDateStart}
                    callbackSelectedDateEnd={callbackSelectedDateEnd}
                    callbackCloseModal={toggleCalendarModal}
                    callbackEventClick={callbackSelectedEvent}
                    saveChanges={saveChanges}
                  />
                </CardBody>
              </Card>
            </Row>
          )}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <ActivityComp viewMoreButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

UsersProfile.propTypes = {
  userProfile: PropTypes.any,
  t: PropTypes.any
};

const mapStateToProps = ({ users }) => ({
  userProfile: users.userProfile
});

export default connect(
  mapStateToProps
)(withRouter(withTranslation()(UsersProfile)));
