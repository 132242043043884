import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// users
import avatar from "../../../assets/images/users/avatar.jpg";
import Notification from "../../Notification";
import ChangePasswordModal from "../../Common/ChangePasswordModal";

const ProfileMenu = props => {

  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState({});
  const [modal, setModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    if (user) {
      setUser(user);
      setUsername(user.username);
    }
  }, []);


  const { changePassSuccess, error } = useSelector(state => ({
    changePassSuccess: state.users.changePassSuccess,
    error: state.users.error
  }));

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (changePassSuccess) {
      setShowNotification(true);
      setMessage(`${props.t("Password change is successful. You must log in with your new password to continue.")}`);
      setNotificationType("Success");
    } else if (Object.keys(error).length > 0) {
      setShowNotification(true);
      setMessage(error.message);
      setNotificationType("Danger");
    }
  }, [changePassSuccess, error]);

  const toggleChangePassword = (bool) => {
    setModal(bool);
  };

  return (
    <React.Fragment>
      {modal && (<ChangePasswordModal
        modal={modal}
        user={user}
        callbackModalResponse={toggleChangePassword}
        callbackSomething={toggleChangePassword}
      />)}
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={avatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{user && user.username || ""}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => toggleChangePassword(true)} className="dropdown-item">
            <i className="bx bx-lock font-size-16 align-middle me-1 text-primary" />
            <span>{props.t("Change Password")}</span>
          </DropdownItem>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
