import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "reactstrap";

//import Charts
//import action

// Pages Components
import WelcomeComp from "./WelcomeComp";
import ActivityComp from "./ActivityComp";
import LastLogins from "./LastLogins";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import Calendar from "../../components/Calendar";

const DoctorDashboard = props => {

  //meta title
  document.title = `CRM | ${props.t("Dashboard")}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
          />
          <Row>
            <Col xl="3">
              <WelcomeComp viewProfileButton={true} />
            </Col>
            <Col xl="9">
              <ActivityComp viewMoreButton={true} />
            </Col>
          </Row>

          <Row>
            <Calendar
              dashboard={true}
            />
          </Row>

          <Row>
            {/*<Col xl="4">
              <SocialSource t={props.t} />
            </Col>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4"></h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            {props.t("Week")}
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            {props.t("Month")}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            {props.t("Year")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div>
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="12">
              <LastLogins
                t={props.t}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

DoctorDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
};

export default withTranslation()(DoctorDashboard);
