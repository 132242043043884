export function DateToString(date, format, seperator) {
  if (format.toUpperCase() === "YYYY-MM-DD")
    return `${date.getFullYear()}${seperator || ""}${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${seperator || ""}${date
      .getDate()
      .toString()
      .padStart(2, "0")}`;
  if (format.toUpperCase() === "DD-MM-YYYY")
    return `${date
      .getDate()
      .toString()
      .padStart(2, "0")}${seperator || ""}${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${seperator || ""}${date.getFullYear()}`;
}

export function StringToDate(string, format, seperator) {
  if (seperator !== " ") {
    if (format.toUpperCase() === "YYYYMMDD") {
      const time = string.split(" ")[1] || "00:00:00";
      const year = string.split(" ")[0].split(seperator)[0];
      const month = string.split(" ")[0].split(seperator)[1];
      const day = string.split(" ")[0].split(seperator)[2];
      const date = `${month} ${day} ${year} ${time}`;
      return formatDate(new Date(date));
    }
    if (format.toUpperCase() === "DDMMYYYY") {
      const time = string.split(" ")[1] || "00:00:00";
      const year = string.split(" ")[0].split(seperator)[2];
      const month = string.split(" ")[0].split(seperator)[1];
      const day = string.split(" ")[0].split(seperator)[0];
      const date = `${month} ${day} ${year} ${time}`;
      return new Date(date);
    }
  } else {
    return undefined;
  }
}

export function formatDate(given) {
  const theDate = new Date(given);
  let day = theDate.getDate();
  let month = theDate.getMonth() + 1;
  const year = theDate.getFullYear();
  let hour = theDate.getHours();
  let minute = theDate.getMinutes();
  let second = theDate.getSeconds();

  if (month.toString().length === 1) {
    month = `0${month}`;
  }
  if (day.toString().length === 1) {
    day = `0${day}`;
  }
  if (hour.toString().length === 1) {
    hour = `0${hour}`;
  }
  if (minute.toString().length === 1) {
    minute = `0${minute}`;
  }
  if (second.toString().length === 1) {
    second = `0${second}`;
  }
  // yyyy-mm-dd hh:mm:ss  format
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function formatDateForScreen(given) {
  const theDate = new Date(given);
  let day = theDate.getDate();
  let month = theDate.getMonth() + 1;
  const year = theDate.getFullYear();
  let hour = theDate.getHours();
  let minute = theDate.getMinutes();

  if (month.toString().length === 1) {
    month = `0${month}`;
  }
  if (day.toString().length === 1) {
    day = `0${day}`;
  }
  if (hour.toString().length === 1) {
    hour = `0${hour}`;
  }
  if (minute.toString().length === 1) {
    minute = `0${minute}`;
  }

  // yyyy-mm-dd hh:mm:ss  format
  return `${day}.${month}.${year}-${hour}:${minute}`;
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

//Target Hour'u (02:00) gibi dakikaya çevirtme
export const calculateMinutesFromTargetTime = (targetHour) => {
  const currentDate = new Date();
  const targetDate = new Date();

  targetDate.setHours(targetHour.split(":")[0], targetHour.split(":")[1], 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const timeDifference = targetDate.getTime() - currentDate.getTime();

  return Math.floor(timeDifference / (1000 * 60));
};

export const convertMinutesToTime = (targetMinutes) => {
  const hours = Math.floor(targetMinutes / 60);
  const minutes = targetMinutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:00`;
};

export function findDoctorsAssistant(doctors, assistant) {
  if (doctors && doctors.length > 0 && assistant && assistant.role && assistant.role.name === "ROLE_DOCTOR") {
    const loggedIn = doctors.filter(item => item.id === assistant.id);
    if (loggedIn && loggedIn[0] && loggedIn[0].isHighestAuth && loggedIn[0].isHighestAuth === true) {
      return loggedIn[0];
    } else {
      const notAssistant = doctors.filter(doc => doc.isHighestAuth === true);
      return notAssistant.filter((doc) => doc.userGroups !== undefined && doc.userGroups[0] !== undefined && doc.userGroups[0].id === loggedIn[0].userGroups[0].id);
    }
  }
}
