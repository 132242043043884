import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { CDate, Email, FirstName, Languages, LastName, Name, Phone, Status } from "./userlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import { getGroups as onGetGroups } from "store/group/actions";
import { getLanguages as onGetLanguages } from "store/language/actions";
import { getCategories as onGetCategories } from "store/category/actions";

import {
  addNewUser as onAddNewUser,
  deleteUser as onDeleteUser,
  resetPassword as onResetPassword,
  updateUser as onUpdateUser,
  userCriteria
} from "store/users/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import Notification from "../../../components/Notification";
import { fileToBase64 } from "../../../common/commonFunctions";
import { get, post } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import categories from "../../../store/category/reducer";
import { getFirmInfos as onGetFirmInfos } from "../../../store/firmInfo/actions";

const isAgent = process.env.REACT_APP_IS_AGENT === "TRUE";
const UsersList = props => {

  //meta title
  document.title = `CRM | ${props.t("Users")}`;

  const initalCont = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "",
    categories: null,
    languages: null,
    isHighestAuth: true,
    status: "ACTIVE"
  };

  const dispatch = useDispatch();
  const [role, setRole] = useState({ label: "", value: "" });
  const [deleteUser, setDeleteUser] = useState([]);
  const [roleUser, setRoleUser] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState(initalCont);
  const [loading, setLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [passModal, setPassModal] = useState(false);
  const [selectedMulti, setselectedMulti] = useState(null);
  const [status, setStatus] = useState({ label: props.t("ACTIVE"), value: "ACTIVE" });
  const [limitationExist, setLimitationExist] = useState(false);
  const [activeUserCount, setActiveUserCount] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [passiveCheck, setPassiveCheck] = useState(false);
  const [multiCategories, setMultiCategories] = useState(null);
  const [mailValid, setMailValid] = useState(false);
  const [mail, setMail] = useState("");
  const [firmInfo, setFirmInfo] = useState({});
  const [groupOptions, setGroupOptions] = useState([]);
  const [switchOn, setSwitchOn] = useState(true);

  useEffect(() => {
    let userReq = {};
    if (!passiveCheck) {
      userReq = Object.assign({ notRole: "ROLE_MODERATOR", status: "ACTIVE" });
    } else {
      userReq = Object.assign({ notRole: "ROLE_MODERATOR" });
    }
    dispatch(userCriteria(userReq));
  }, [passiveCheck]);

  const { users } = useSelector(state => ({
    users: state.users.users
  }));

  const { firmInfos, firmError } = useSelector(state => ({
    firmInfos: state.FirmInfo.firmInfos,
    firmError: state.FirmInfo.error
  }));

  useEffect(() => {
    dispatch(onGetFirmInfos());
  }, []);


  useEffect(() => {
    if (firmInfos && firmInfos.length > 0) {
      setFirmInfo(firmInfos[0]);
    }
  }, [firmInfos]);

  useEffect(() => {
    get(url.SYSTEM_PARAMETER_BY_CODE + "/USE_USER_LIMITATION").then(response => {
      if (response.data && response.data.value === "NO") {
        setLimitationExist(false);
      } else {
        setLimitationExist(true);
        if (Object.entries(firmInfo).length > 0 && firmInfo.userCount) {
          setUserLimit(firmInfo.userCount);
        }
        const countBody = Object.assign({ status: "ACTIVE", notRole: "ROLE_MODERATOR" });
        post(url.GET_ACTIVE_USER_COUNT, countBody).then(response => {
          setActiveUserCount(response.data);
        });
      }
    });
  }, [firmInfo]);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    setRoleUser(loggedInUser && loggedInUser.role && loggedInUser.role.name === "ROLE_USER");
  }, [roleUser]);

  useEffect(() => {
    const langs = user.languages !== null ? user.languages.map(lang => ({
      label: lang.name,
      value: lang
    })) : null;
    setselectedMulti(langs);
  }, [user]);


  function handleMulti(selectedMulti) {
    const langCodes = selectedMulti.map((lang) => lang.value);
    setselectedMulti(selectedMulti);
    setUser({
      ...user,
      ["languages"]: langCodes
    });
  }

  useEffect(() => {
    const cats = user.categories !== null ? user.categories.map(cat => ({
      label: cat.name,
      value: cat
    })) : null;
    setMultiCategories(cats);
  }, [user]);


  function handleCatMulti(data) {
    const catCodes = data.map((cat) => cat.value);
    setMultiCategories(data);
    setUser({
      ...user,
      ["categories"]: catCodes
    });
  }

  const { languages } = useSelector(state => ({
    languages: state.Languages.languages
  }));

  const languageOptions = [];
  languages.map((item) => {
    languageOptions.push({ label: item.name, value: item });
  });

  const { categories } = useSelector(state => ({
    categories: state.categories.categories
  }));

  const categoryOptions = [];
  categories.map((item) => {
    categoryOptions.push({ label: item.name, value: item });
  });

  const { groups } = useSelector(state => ({
    groups: state.Group.groups
  }));

  useEffect(() => {
    const groupOptions = [];
    if (groups && groups.length > 0) {
      groups.map((group) => {
        if (group.roles && group.roles.length > 0) {
          if (isAgent) {
            if (group.roles && group.roles[0].name !== "ROLE_MODERATOR") {
              groupOptions.push({ label: group.name, value: group });
            }
          } else {
            if (group.roles && group.roles[0].name !== "ROLE_MODERATOR" && group.roles[0].name !== "ROLE_DOCTOR") {
              groupOptions.push({ label: group.name, value: group });
            }
          }
        }
      });
    }
    setGroupOptions(groupOptions);
  }, [groups]);


  const statusOptions = [{ label: props.t("ACTIVE"), value: "ACTIVE" },
    { label: props.t("PASSIVE"), value: "PASSIVE" },
    { label: props.t("BLOCKED"), value: "BLOCKED" }];

  const onChangeInRoleSelect = event => {
    setUser({
      ...user,
      ["userGroups"]: [event.value]
    });
    setRole(event);
  };

  const onChangeInStatusSelect = event => {
    setUser({
      ...user,
      ["status"]: event.value
    });
    setStatus(event);
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Avatar")}`,
        Header: "Avatar",
        disableFilters: true,
        filterable: false,
        accessor: (cellProps) => {
          let convertedPhoto = null;
          if (cellProps.photo) {
            convertedPhoto = cellProps.photo.toString();
          }
          return (convertedPhoto !== null ? (
            <img src={convertedPhoto} style={{ height: 50, width: 50, flex: 1 }} />
          ) : (
            <div style={{ height: 50, width: 50, flex: 1 }}>
                <span className="avatar-title rounded-circle">
                        {cellProps.username ? cellProps.username.charAt(0) : "?"}
                </span>
            </div>
          ));
        }
      },
      {
        HeaderLabel: `${props.t("Username")}`,
        Header: "Kullanıcı Adı",
        accessor: "username",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Firstname")}`,
        Header: "İsim",
        accessor: "firstName",
        filterable: true,
        Cell: cellProps => {
          return <FirstName {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Lastname")}`,
        Header: "Soyisim",
        accessor: "lastName",
        filterable: true,
        Cell: cellProps => {
          return <LastName {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Phone")}`,
        Header: "Phone",
        accessor: "phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <Phone {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Email")}`,
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Languages")}`,
        Header: "Languages",
        accessor: "languages",
        filterable: true,
        Cell: cellProps => {
          return <Languages {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Categories")}`,
        Header: "Categories",
        accessor: "categories",
        filterable: true,
        Cell: cellProps => {
          return <Languages {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Status")}`,
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Create Date")}`,
        Header: "Cdate",
        accessor: "createDate",
        filterable: true,
        Cell: cellProps => {
          return <CDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-warning"
                onClick={() => {
                  setUser(cellProps.row.original),
                    toggleChangePassword();
                }}
                disabled={roleUser}
              >
                <i className="mdi mdi-lock font-size-18" id="resetpasstooltip" />
                <UncontrolledTooltip placement="top" target="resetpasstooltip">
                  {props.t("Reset Password")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
                disabled={roleUser}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    dispatch(onGetGroups());
  }, []);

  useEffect(() => {
    dispatch(onGetLanguages());
  }, []);

  useEffect(() => {
    dispatch(onGetCategories());
  }, []);

  const toggle = () => {
    if (modal) {
      setUser(initalCont);
      setRole({ label: "", value: "" });
      setStatus({ label: "", value: "" });
      setModal(false);
      setIsEdit(false);
      setMail("");
      setselectedMulti(null);
      setMultiCategories(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg.original;

    setUser({
      id: user.id,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      phoneNumber: user.phoneNumber,
      email: user.email,
      languages: user.languages,
      categories: user.categories,
      userGroups: user.userGroups,
      status: user.status,
      isHighestAuth: user.isHighestAuth
    });
    user.userGroups ? setRole({ label: user.userGroups[0].name, value: user.groups }) : null;
    user.status ? setStatus({ label: user.status, value: user.status }) : null;
    user.email ? setMail(user.email) : "";
    user.isHighestAuth ? setSwitchOn(user.isHighestAuth) : setSwitchOn(false);
    setIsEdit(true);

    if (roleUser) {
      setShowNotification(true);
      setMessage(`${props.t("You have no permission for this action")}`);
      setNotificationType("Warning");
    } else {
      toggle();
    }
  };
  //delete lead
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = user => {
    setDeleteUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(deleteUser.id));
    setDeleteModal(false);
    setLoading(true);
  };

  const handleRefresh = () => {
    const userReq = Object.assign({ notRole: "ROLE_MODERATOR", status: "ACTIVE" });
    dispatch(userCriteria(userReq));
  };

  const handleChangeMail = (e) => {
    const regEXpMail = /^\S+@\S+\.\S+$/;
    setMailValid(false);
    const mail = e.target.value.trim();
    setMail(mail);
    if (regEXpMail.test(mail)) {
      setMailValid(true);
      setUser({
        ...user,
        ["email"]: mail
      });
    }
  };

  function handleIsGroupAdmin(val) {
    setUser({
      ...user,
      isHighestAuth: val
    });
    setSwitchOn(val);
  }

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const handleChangePhoto = (e) => {
    fileToBase64(e.target.files[0]).then(response => {
      setUser({
        ...user,
        ["photo"]: response
      });
    });

  };

  const handleChangePhone = (e) => {
    const regExpPhone = /^\d{0,15}$/;
    let phone = e.target.value.replaceAll("+", "");
    if (regExpPhone.test(phone)) {
      setUser({
        ...user,
        ["phoneNumber"]: phone
      });
    }
  };

  const sendResetPassRequest = () => {
    dispatch(onResetPassword(user));
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const saveUser = () => {
    if (isEdit) {
      dispatch(onUpdateUser(user));
    } else {
      if (user.username === "" || user.phoneNumber === "" || user.email === "") {
        setMessage(`${props.t("Please fill mandatory fields")}`);
        setShowNotification(true);
        setNotificationType("Warning");
      } else {
        dispatch(onAddNewUser(user));
      }
    }
    toggle();
  };

  const { errorMessage, resetPassSuccess } = useSelector(state => ({
    errorMessage: state.users.error,
    resetPassSuccess: state.users.resetPassSuccess
  }));

  useEffect(() => {
    if (Object.entries(errorMessage).length > 0) {
      setMessage(errorMessage.message);
      setShowNotification(true);
      setNotificationType("Danger");
    } else if (resetPassSuccess) {
      setMessage(props.t("Success"));
      setShowNotification(true);
      setNotificationType("Success");
      toggleChangePassword();
    }
  }, [errorMessage, resetPassSuccess]);

  const toggleChangePassword = () => {
    setPassModal(!passModal);
  };

  const toolbar = (
    <div id="toolbar" className="align-content-end">
      <div className="col-12">
        <div className="col">
          <div className="align-middle p-3">
            <label
              className="form-check-label"
              onClick={() => setPassiveCheck(!passiveCheck)}
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="getPassiveUsers"
                onChange={() => setPassiveCheck(passiveCheck)}
                checked={passiveCheck}
              />
              {props.t("Include Passive/Blocked Users")}
            </label>
          </div>
        </div>
        <div className="col">
          <span className="align-middle p-3 text-info mb-3">
                  {limitationExist && (props.t("Your remaining user creation limit is: ") + activeUserCount + " / " + userLimit)}
                </span>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <WarningModal
            show={deleteModal}
            onApproveClick={handleDeleteUser}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          {!roleUser && passModal && (
            <WarningModal
              show={passModal}
              onCloseClick={toggleChangePassword}
              onApproveClick={sendResetPassRequest}
              title={props.t("RESET-PASS-MESSAGE", { username: user.username })}
              message={user.email}
            />
          )}
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Users")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddOptions={!roleUser}
                    handleAddClick={toggle}
                    handleRowClick={handleUserClick}
                    customPageSize={25}
                    toolbar={toolbar}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Row form="true">
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Username")}</Label>
                            <Input
                              name="username"
                              type="text"
                              onChange={handleChange}
                              value={user.username || ""}
                              disabled={roleUser}
                              invalid={!user.username}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Firstname")}</Label>
                            <Input
                              name="firstName"
                              label="İsim"
                              type="text"
                              onChange={handleChange}
                              disabled={roleUser}
                              value={user.firstName || ""}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Lastname")}</Label>
                            <Input
                              name="lastName"
                              label="Soyisim"
                              type="text"
                              onChange={handleChange}
                              disabled={roleUser}
                              value={user.lastName || ""}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Email")}</Label>
                            {!mailValid && (
                              <Alert color="warning" style={{ marginTop: "3px" }}>
                                {props.t("Please enter a valid email address. This email address will be used for password reset and notifications.")}
                              </Alert>
                            )}
                            <Input
                              name="email"
                              label="Email"
                              type="email"
                              onChange={handleChangeMail}
                              disabled={roleUser}
                              value={mail}
                              invalid={!user.email}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Phone")}</Label>
                            <Input
                              name="phoneNumber"
                              label="Phone"
                              type="tel"
                              onChange={handleChangePhone}
                              value={user.phoneNumber || ""}
                              invalid={!user.phoneNumber}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="control-label">
                              {props.t("Languages")}
                            </label>
                            <Select
                              value={selectedMulti}
                              isMulti={true}
                              onChange={(event) => {
                                handleMulti(event);
                              }}
                              placeholder={props.t("Select")}
                              options={languageOptions}
                              className="select2-selection"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="control-label">
                              {props.t("Categories")}
                            </label>
                            <Select
                              value={multiCategories}
                              isMulti={true}
                              onChange={(event) => {
                                handleCatMulti(event);
                              }}
                              placeholder={props.t("Select")}
                              options={categoryOptions}
                              className="select2-selection"
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Photo")}</Label>
                            <Input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={handleChangePhoto}
                            />
                          </div>
                          <div className="mb-3">
                            {isAgent ? (
                                <Row>
                                  <Col className="col-sm-7">
                                    <Label className="form-label">{props.t("Role")}<i
                                      className="mdi mdi-alert-circle-outline error me-1" /></Label>
                                    <Select
                                      value={role}
                                      onChange={(e) => {
                                        onChangeInRoleSelect(e);
                                      }}
                                      options={groupOptions}
                                    />
                                  </Col>
                                  <Col className="col-sm-5">
                                    <Col>
                                      <Label className="form-label">{props.t("Is User Group Admin ?")} <i
                                        className="mdi mdi-alert-circle-outline error me-1" /></Label>
                                    </Col>
                                    <Col>
                                      <Row>
                                        <Col>
                                          <div className="form-check-success">
                                            <label
                                              className="form-check-label me-2"
                                              htmlFor="groupAdminYes"
                                            >
                                              {props.t("YES")}
                                            </label>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="groupAdminYes"
                                              id="groupAdminYes"
                                              checked={switchOn}
                                              onClick={() => handleIsGroupAdmin(true)}
                                            />
                                          </div>
                                        </Col>

                                        <Col>
                                          <div className="form-check-danger">
                                            <label
                                              className="form-check-label me-2"
                                              htmlFor="groupAdminNo"
                                            >
                                              {props.t("NO")}
                                            </label>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="groupAdminNo"
                                              id="groupAdminNo"
                                              checked={!switchOn}
                                              onClick={() => handleIsGroupAdmin(false)}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Col>
                                </Row>
                              )
                              : (
                                <>
                                  <Label className="form-label">{props.t("Role")} <i
                                    className="mdi mdi-alert-circle-outline error me-1" /></Label>
                                  <Select
                                    value={role}
                                    onChange={(e) => {
                                      onChangeInRoleSelect(e);
                                    }}
                                    options={groupOptions}
                                  />
                                </>
                              )}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Status")} <i
                              className="mdi mdi-alert-circle-outline error me-1" /></Label>
                            <Select
                              value={status}
                              onChange={(e) => {
                                onChangeInStatusSelect(e);
                              }}
                              options={statusOptions}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                              onClick={saveUser}
                              disabled={!isEdit && (user.username === "" || user.email === "" || user.phoneNumber === "") || status.value === "" || role.value === ""}
                            >
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(UsersList));
