import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CloseButton,
  Col,
  Collapse,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";

import { addNewPatient, deletePatient, patientCriteria } from "store/patient/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { Category, City, CreateDate, Phone, Surname, UpdateDate } from "./PatientCol";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import PatientDetail from "./PatientDetail";
import Notification from "../../components/Notification";
import { phoneOptions } from "../../constants/phoneCodes";
import { phoneOptionsTurkish } from "../../constants/phoneCodesTurkish";
import { Name } from "../Users/UserList/userlistCol";
import { getWithoutToken } from "../../helpers/axios_with_headers";
import { isEmpty } from "lodash";
import { userCriteria } from "../../store/users/actions";
import CustomSelectableTable from "../../components/Common/CustomSelectableTable";

const Patients = props => {

    //meta title
    document.title = `CRM | ${props.t("Patients")}`;

    const dispatch = useDispatch();

    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    const roleUser = (loggedInUser && loggedInUser.role && loggedInUser.role.name === "ROLE_USER");
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");

    const [size, setSize] = useState(50);
    const [activeTab, setActiveTab] = useState(0);
    const [modal, setModal] = useState(false);
    const [patient, setPatient] = useState(null);
    const [gender, setGender] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [callbackClose, setCallbackClose] = useState(false);
    let [phoneCode, setPhoneCode] = useState(phoneOptions[0]);
    const [openedTabs, setOpenedTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [dropDownShown, setDropDownShown] = useState(false);
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [clearSelected, setClearSelected] = useState(false);
    const [filterDoctor, setFilterDoctor] = useState(!roleUser ? {} : {
      label: loggedInUser.username,
      value: loggedInUser
    });
    const [filterName, setFilterName] = useState("");
    const [filterCityName, setFilterCityName] = useState("");
    const [filterPhone, setFilterPhone] = useState("");
    const [filterCreateDateStart, setFilterCreateDateStart] = useState("");
    const [filterCreateDateEnd, setFilterCreateDateEnd] = useState("");
    const [filterUpdateDateStart, setFilterUpdateDateStart] = useState("");
    const [filterUpdateDateEnd, setFilterUpdateDateEnd] = useState("");
    const [filterUpdateUser, setFilterUpdateUser] = useState("");
    const [filterIdentityNumber, setFilterIdentityNumber] = useState("");
    const [dataPerPage, setDataPerPage] = useState(50);
    const [isOpen, setIsOpen] = useState(false);
    const [updateUserOptions, setUpdateUserOptions] = useState([]);
    const [sizeButtonDisabled, setSizeButtonDisabled] = useState(false);
    const [mailValid, setMailValid] = useState(true);
    const [mail, setMail] = useState(patient && patient.email || "");
    const [clinicOptions, setClinicOptions] = useState({});

    const genderOptions = [{ label: props.t("MALE"), value: "MALE" },
      { label: props.t("FEMALE"), value: "FEMALE" }];

    const { patients, page, totalCount, totalPages, error, processResponse } = useSelector(state => ({
      patients: state.patients.patients,
      page: state.patients.page,
      totalCount: state.patients.totalCount,
      totalPages: state.patients.totalPages,
      error: state.patients.error,
      processResponse: state.patients.processResponse
    }));

    const initialBody = !roleUser ? { size: size, page: page ? page : 0, sort: "updateDate" } : {
      size: size,
      page: page ? page : 0,
      sort: "updateDate"
    };

    const [body, setBody] = useState(initialBody);

    useEffect(() => {
      dispatch(patientCriteria(body));
    }, []);

    useEffect(() => {
      getDoctorsAndClinics();
    }, []);

    function getDoctorsAndClinics() {
      getWithoutToken(process.env.REACT_APP_API_URL + "/userGroup/findHighAuthUsersWithDepartment").then(response => {
        if (response.status === 200) {
          const clinicList = response.data.map(item => ({ label: item.department, value: item }));
          setClinicOptions(clinicList);
        }
      });
    }

    const { doctors } = useSelector(state => ({
      doctors: state.users.users,
    }));

    useEffect(() => {
      let userReq = Object.assign(body, { notRole: "ROLE_MODERATOR", status: "ACTIVE", role: "ROLE_DOCTOR" });
      dispatch(userCriteria(userReq));
    }, []);

    const toggleFilter = () => setIsOpen(!isOpen);

    const onChangeInGenderSelect = event => {
      setPatient({
        ...patient,
        ["gender"]: event !== null ? event.value : null
      });
      setGender((event));
    };

    const handleChangeFilterName = (e) => {
      setFilterName(e.target.value);
      setBody({
        ...body,
        ["fullName"]: e.target.value
      });
    };

    const handleChangeFilterCityName = (e) => {
      setFilterCityName(e.target.value);
      setBody({
        ...body,
        ["cityName"]: e.target.value
      });
    };

    const handleChangeFilterPhoneNumber = (e) => {
      setFilterPhone(e.target.value);
      setBody({
        ...body,
        ["phoneNumber"]: e.target.value
      });
    };

    function handleFilterCreateDateStart(e) {
      setFilterCreateDateStart(e.target.value);
      setBody({
        ...body,
        ["createDateStart"]: e.target.value
      });
    }

    function handleFilterCreateDateEnd(e) {
      setFilterCreateDateEnd(e.target.value);
      setBody({
        ...body,
        ["createDateEnd"]: e.target.value
      });
    }

    function handleFilterUpdateDateStart(e) {
      setFilterUpdateDateStart(e.target.value);
      setBody({
        ...body,
        ["updateDateStart"]: e.target.value
      });
    }

    function handleFilterUpdateDateEnd(e) {
      setFilterUpdateDateEnd(e.target.value);
      setBody({
        ...body,
        ["updateDateEnd"]: e.target.value
      });
    }

    const handleChangeMail = (e) => {
      const regEXpMail = /^\S+@\S+\.\S+$/;
      setMailValid(false);
      const mail = e.target.value.trim();
      setMail(mail);
      if (regEXpMail.test(mail)) {
        setMailValid(true);
        setPatient({
          ...patient,
          ["email"]: mail
        });
      }
    };

    const handleChange = (e) => {
      switch (e.target.type) {
        case "text":
          setPatient({
            ...patient,
            [e.target.name]: e.target.value
          });
          break;
        case "textarea":
          setPatient({
            ...patient,
            [e.target.name]: e.target.value
          });
          break;
        default :
          setPatient({
            ...patient,
            [e.target.name]: e.target.value
          });
          break;
      }

    };

    const handleChangePhone = (e) => {
      let str = e.target.value.toString();
      let phoneCode = "";
      let tel = "";
      if (str.includes("+")) {
        phoneCode = str.split(/\s/)[0];
        findPhoneCode(phoneCode);
        tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
      } else {
        tel = str.replaceAll(/\s+/g, "");
      }
      setPatient({
        ...patient,
        ["phoneNumber"]: tel
      });
    };

    const findPhoneCode = (code) => {
      if (currentLanguage === "en") {
        phoneOptions.forEach(cd => {
          if (cd.value === code)
            setPhoneCode(cd);
        });
      } else {
        phoneOptionsTurkish.forEach(cd => {
          if (cd.value === code)
            setPhoneCode(cd);
        });
      }
    };

    const validation = {
      values: {
        firstName: (patient && patient.firstName) || "",
        lastName: (patient && patient.lastName) || "",
        phoneNumber: (patient && patient.phoneNumber) || "",
        email: (patient && patient.email) || "",
        note: (patient && patient.note) || "",
        cityName: (patient && patient.cityName) || "",
        createDate: (patient && patient.createDate) || "",
        identityNumber: (patient && patient.identityNumber) || "",
        birthday: (patient && patient.birthday) || "",
        gender: (patient && patient.gender) || null
      },

      handleSubmit: (values) => {
        const newPatient = {
          firstName: values["firstName"],
          lastName: values["lastName"],
          phoneNumber: phoneCode.value + values["phoneNumber"],
          email: values["email"],
          note: values["note"],
          cityName: values ["cityName"],
          createDate: values["createDate"],
          birthday: values["birthday"],
          identityNumber: values["identityNumber"],
          gender: values["gender"]
        };

        dispatch(addNewPatient(newPatient));
      }
    };

    useEffect(() => {
      if (!isEmpty(error)) {
        setShowNotification(true);
        setMessage(error.message);
        setNotificationType("Danger");
      } else if (processResponse) {
        setShowNotification(true);
        setMessage("Değişiklikler Kaydedildi");
        setNotificationType("Success");
        if (modal) {
          toggle();
        }
      }
    }, [error, processResponse]);

    const handlePatientClick = row => {
      const selected = row.original;

      setPatient({
        id: selected.id,
        firstName: selected.firstName,
        lastName: selected.lastName,
        phoneNumber: selected.phoneNumber,
        email: selected.email,
        note: selected.note,
        cityName: selected.cityName,
        birthday: selected.birthday,
        createDate: selected.createDate
      });

      selected.gender ? setGender({ label: selected.gender, value: selected.gender }) : null;

      const exist = checkPatientExistOnOpenedTab(selected);
      if (exist) {
        toggleTab("toggle", exist.tabIndex, selected);
      } else {
        createTab(tabIndex + 1, selected);
      }
    };

    function handleSelectAll(data) {
      if (data.length > 0) {
        setDropDownShown(true);
        setSelectedPatients(data);
      } else {
        setDropDownShown(false);
        setSelectedPatients([]);
      }
    }

    function handleSelect(data) {
      if (data.length > 0) {
        setDropDownShown(true);
        setSelectedPatients(data);
      } else {
        setDropDownShown(false);
        setSelectedPatients([]);
      }
    }

    const columns = useMemo(
      () => [
        {
          HeaderLabel: `${props.t("Firstname")}`,
          accessor: "firstName",
          Cell: (cellProps) => {
            return <Name {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Lastname")}`,
          accessor: "lastName",
          Cell: (cellProps) => {
            return <Surname {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Phone")}`,
          accessor: "phoneNumber",
          Cell: (cellProps) => {
            return <Phone {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("City")}`,
          accessor: "cityName",
          Cell: (cellProps) => {
            return <City {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Create Date")}`,
          accessor: "createDate",
          Cell: (cellProps) => {
            return <CreateDate {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Update Date")}`,
          accessor: "updateDate",
          Cell: (cellProps) => {
            return <UpdateDate {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Update User")}`,
          accessor: "updateUser.fullName",
          Cell: (cellProps) => {
            return <Category {...cellProps} />;
          }
        }
      ],
      []
    );

    const onChangePhoneCode = (e) => {
      setPhoneCode({ label: e.target.label, value: e.target.value });
    };

    const toggle = () => {
      if (modal) {
        setModal(false);
        setPatient(null);
        setGender(null);
        setMail("");
      } else {
        setModal(true);
        setPatient(null);
        setGender(null);
        setMail("");
        setPhoneCode(phoneOptions[0]);
      }
    };

    const closeTab = (tab) => {
      const founded = openedTabs.findIndex(item => item.tabIndex === tab.tabIndex);
      const filtered = openedTabs.filter(item => item.tabIndex !== tab.tabIndex);
      setOpenedTabs(filtered);
      if (founded === 0) {
        toggleTab("toggle", 0);
      } else {
        toggleTab("toggle", openedTabs[founded - 1].tabIndex, openedTabs[founded - 1].patient);
      }
    };

    const checkPatientExistOnOpenedTab = (custCheck) => {
      if (openedTabs.some(openedTab => openedTab.patient.id === custCheck.id)) {
        return true;
      }
    };

    const createTab = (tabInd, cust) => {
      openedTabs.push({ tabIndex: tabInd, patient: cust });
      setOpenedTabs(openedTabs);
      setTabIndex(tabInd);
      setActiveTab(tabInd);
      toggleTab("toggle", tabInd, cust);
    };

    const toggleTab = (action, tab, cust) => {
      if (tab === 0) {
        setActiveTab(0);
        return;
      }
      if (checkPatientExistOnOpenedTab(cust)) {
        const exist = openedTabs.filter(openedTab => openedTab.patient.id === cust.id);
        if (action === "toggle") {
          setActiveTab(exist[0].tabIndex);
          setPatient(exist[0].patient);
        } else {
          closeTab(exist[0]);
        }
      }
    };

    //delete Patient
    const [deleteModal, setDeleteModal] = useState(false);

    const handleRefresh = () => {
      dispatch(patientCriteria(body));
    };

    const handleNavClick = (e, openedTab) => {
      if (e.target.id.split("-")[0] === ("close")) {
        closeTab(openedTab);
      } else {
        toggleTab("toggle", openedTab.tabIndex, openedTab.patient);
      }
    };
    const handleDeletePatient = () => {
      setDeleteModal(false);
      selectedPatients.forEach(cust => {
          dispatch(deletePatient(cust));
          toggleTab("close", null, cust);
        }
      );

      handleRefresh();
      setSelectedPatients([]);
      setDropDownShown(false);
    };

    const handlePatientClicks = () => {
      toggle();
    };

    const callBackClose = (val) => {
      setMessage("");
      setShowNotification(val);
      setCallbackClose(false);
    };

    useEffect(() => {
      setActiveTab(activeTab);
    }, [activeTab]);

    useEffect(() => {
      if (selectedPatients.length === 0) {
        setClearSelected(true);
      } else {
        setClearSelected(false);
      }
    }, [selectedPatients]);

    const closeAllTabs = () => {
      toggleTab("toggle", 0, null);
      setOpenedTabs([]);
    };

    const handleFilteredPatientData = (req) => {
      if (req) {
        dispatch(patientCriteria(req));
      } else {
        dispatch(patientCriteria(body));
      }
    };

    function handleClearFilterInputs() {
      setFilterDoctor({});
      setFilterName("");
      setFilterCityName("");
      setFilterPhone("");
      setFilterCreateDateStart("");
      setFilterCreateDateEnd("");
      setFilterUpdateDateStart("");
      setFilterUpdateDateEnd("");
      setFilterUpdateUser("");
      setBody(initialBody);
      handleFilteredPatientData(initialBody);
    }

    function handleChangeFilterIdentityNumber(e) {
      setFilterIdentityNumber(e.target.value);
      setBody({
        ...body,
        ["identityNumber"]: e.target.value
      });
    }

    const searchBar = (
      <div className="custom-accordion">
        <Link
          className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
          onClick={toggleFilter}
          to="#"
        >
          <i className="mdi mdi-filter font-size-15 text-secondary me-1"></i>{" "}
          {props.t("Filters")}
          <i
            className={
              isOpen
                ? "mdi mdi-chevron-up accor-down-icon ms-2"
                : "mdi mdi-chevron-down accor-down-icon ms-2"
            }
          />
        </Link>
        <Collapse isOpen={isOpen}>
          <div className="row">
            <div className="container col-11 mt-1 d-inline-block align-content-end">
              <div className="row">
                <div className="mb-3 col-xs-12 col-md-3">
                  <Label className="form-label">{props.t("Fullname")}</Label>
                  <Input
                    name="fullname"
                    type="text"
                    value={filterName}
                    onChange={(e) => handleChangeFilterName(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Phone")}</Label>
                  <Input
                    name="phone"
                    type="text"
                    value={filterPhone}
                    onChange={(e) => handleChangeFilterPhoneNumber(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("City")}</Label>
                  <Input
                    name="city"
                    type="text"
                    value={filterCityName}
                    onChange={(e) => handleChangeFilterCityName(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Identity Number")}</Label>
                  <Input
                    name="city"
                    type="text"
                    value={filterIdentityNumber}
                    onChange={(e) => handleChangeFilterIdentityNumber(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Create Date Start")}</Label>
                  <Input
                    id="start-date"
                    name="start-date"
                    type="date"
                    onChange={e => handleFilterCreateDateStart(e)}
                    value={filterCreateDateStart}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Create Date End")}</Label>
                  <Input
                    id="end-date"
                    name="end-date"
                    type="date"
                    onChange={e => handleFilterCreateDateEnd(e)}
                    value={filterCreateDateEnd}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Update Date Start")}</Label>
                  <Input
                    id="update-date-start"
                    name="update-date-start"
                    type="date"
                    onChange={e => handleFilterUpdateDateStart(e)}
                    value={filterUpdateDateStart}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Update Date End")}</Label>
                  <Input
                    id="update-date-end"
                    name="update-date-end"
                    type="date"
                    onChange={e => handleFilterUpdateDateEnd(e)}
                    value={filterUpdateDateEnd}
                  />
                </div>
              </div>
            </div>
            <div className="col-1 mt-5 d-inline-block align-content-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleFilteredPatientData()}
              >
                <i className="mdi mdi-search-web me-1" />
                {props.t("Filter")}
              </Button>
              <Button
                type="button"
                color="warning"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleClearFilterInputs()}
              >
                <i className="mdi mdi-format-clear me-1" />
                {props.t("Clear All Filters")}
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    );

    function handlePreviousPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage - 1 });
      dispatch(patientCriteria(changedBody));
      setClearSelected(true);
    }

    function handleNextPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage + 1 });
      dispatch(patientCriteria(changedBody));
      setClearSelected(true);
    }

    function handleSizePerPage() {
      setSizeButtonDisabled(true);
      setSize(dataPerPage);
      const changedBody = Object.assign(body, { size: dataPerPage, page: 0 });
      dispatch(patientCriteria(changedBody));
      setClearSelected(true);
    }

    return (
      <React.Fragment>
        <WarningModal
          id="deleteModal"
          show={deleteModal}
          onApproveClick={handleDeletePatient}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div id="container" className="container-fluid">
          <Notification
            type={notificationType}
            message={message}
            show={showNotification}
            callbackShow={callBackClose}
          />
          <Breadcrumbs title={props.t("Patients")} />
          <Row>
            <Card id="custList">
              <CardTitle>
                <Row className="mt-4">
                  <Col lg="10">
                  </Col>
                  <Col lg="2">
                    {openedTabs.length > 0 ? (
                      <Button
                        id="closeTabs"
                        type="button"
                        color="warning"
                        className="btn-rounded btn-sm mt-2"
                        onClick={() => closeAllTabs()}
                      >
                        <i className="mdi mdi-crosshairs me-1" />
                        {props.t("Close All Tabs")}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </CardTitle>
              <CardBody>
                <div className="crypto-buy-sell-nav" id="contain">
                  <Nav tabs className="nav-tabs-custom" role="tablist">
                    <NavItem key={0}>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0
                        })}
                        onClick={() => {
                          toggleTab("toggle", 0);
                        }}
                      >
                        {props.t("Patients")}
                      </NavLink>
                    </NavItem>
                    {openedTabs.length > 0 ? (
                      openedTabs.map((openedTab, key) => (
                        <NavItem id={`nav-item${key}`} key={key} className="nav-item">
                          <NavLink
                            id={`link-${key}`}
                            className={classnames({
                              active: activeTab === openedTab.tabIndex
                            }, "nav-link")}
                            onClick={(e) => handleNavClick(e, openedTab)}
                          >
                            <span>{`${openedTab.patient.firstName} ${openedTab.patient.lastName}`} </span>
                            <CloseButton
                              id={`close-${key}`}
                              className="btn-xs p-0"
                              onClick={(event) => handleNavClick(event, openedTab)}
                            />
                          </NavLink>
                        </NavItem>
                      ))
                    ) : null}
                  </Nav>
                  <TabContent
                    id="tab-content"
                    activeTab={activeTab}
                    className="p-3"
                  >
                    <TabPane tabId={0} id="custListTab">
                      {searchBar}
                      <React.Fragment>
                        <Col className="col-12 mt-2">
                          {props.t("Total Data Count")} = {totalCount}
                        </Col>
                        <div>
                          <CustomSelectableTable
                            refreshButton={true}
                            handleRefreshClick={handleRefresh}
                            columns={columns}
                            data={patients}
                            isAddOptions={true}
                            handleAddClick={handlePatientClicks}
                            handleRowClick={handlePatientClick}
                            customPageSize={size}
                            //  handleChangeSelect={handleSelect}
                            //  handleChangeSelectAll={handleSelectAll}
                            //  toolbar={toolbar}
                            //  clearAllSelected={clearSelected}
                            className="custom-header-css"
                            isExportEnabled={loggedInUser && loggedInUser.role && loggedInUser.role.name === "ROLE_MODERATOR"}
                          />
                          <Row className="col-12 mb-5">
                            <Col className="col-3">
                              <InputGroup>
                                <Input
                                  min={0}
                                  style={{ width: 70 }}
                                  max={totalPages}
                                  value={dataPerPage}
                                  onChange={(e) => setDataPerPage(e.target.value)}
                                />
                                <Button size="sm" className="btn btn-outline-secondary" disabled={sizeButtonDisabled}
                                        onClick={handleSizePerPage}>{props.t("Set Size")}
                                </Button>
                              </InputGroup>
                            </Col>
                            <Col className="col-8" />
                            <Col className="col-1 align-content-end">
                              <div className="btn-group me-0" role="group">
                                <button type="button" className="btn btn-outline-secondary" disabled={page === 0}
                                        onClick={() => handlePreviousPage(page)}>
                                  <i className="bx bx-chevron-left"></i>
                                </button>
                                <button type="button" className="btn btn-outline-secondary">
                                  {page + 1}
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        disabled={page === totalPages}
                                        onClick={() => handleNextPage(page)}>
                                  <i className="bx bx-chevron-right"></i>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <Modal isOpen={modal} toggle={toggle} size="lg">
                          <ModalHeader toggle={toggle} tag="h4">
                            {props.t("Add")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              id="new-patient"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit(validation.values);
                                return false;
                              }}
                            >
                              <Row form="true">
                                <Col className="col-12">
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Firstname")}</Label>
                                      <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        onChange={handleChange}
                                        value={validation.values.firstName || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Lastname")}</Label>
                                      <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        onChange={handleChange}
                                        value={validation.values.lastName || ""}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("ID Number")}</Label>
                                      <Input
                                        id="IDNumber"
                                        name="identityNumber"
                                        type="text"
                                        onChange={handleChange}
                                        value={validation.values.identityNumber || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Phone")}</Label>
                                      <InputGroup>
                                        <select
                                          id="phoneCode"
                                          className="form-select"
                                          value={phoneCode.value}
                                          onChange={onChangePhoneCode}
                                        >
                                          {currentLanguage === "en" ? (
                                            phoneOptions.map(code => (
                                              <option id={code.value} key={code.label} value={code.value}>
                                                {code.label}
                                              </option>
                                            ))
                                          ) : (phoneOptionsTurkish.map(code => (
                                            <option id={code.value} key={code.label} value={code.value}>
                                              {code.label}
                                            </option>
                                          )))}
                                        </select>
                                        <Input
                                          id="phoneNum"
                                          name="phoneNumber"
                                          type="text"
                                          onChange={handleChangePhone}
                                          value={validation.values.phoneNumber || ""}
                                          required={true}
                                          placeholder="--- --- ----"
                                          invalid={!validation.values.phoneNumber}
                                        />
                                      </InputGroup>
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("E-mail")}</Label>
                                      <Input
                                        name="email"
                                        label="Email"
                                        type="email"
                                        onChange={handleChangeMail}
                                        value={mail}
                                        invalid={(mail.length > 0 && !mailValid)}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Gender")}</Label>
                                      <Select
                                        id="genderSelect"
                                        value={gender}
                                        onChange={(e) => {
                                          onChangeInGenderSelect(e);
                                        }}
                                        options={genderOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("City")}</Label>
                                      <Input
                                        id="cityName"
                                        name="cityName"
                                        type="text"
                                        onChange={handleChange}
                                        value={validation.values.cityName || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Birth Date")}</Label>
                                      <Input
                                        id="birthday"
                                        name="birthday"
                                        type="date"
                                        onChange={handleChange}
                                        value={validation.values.birthday || ""}
                                      />
                                    </div>
                                  </Row>
                                  <div className="mb-3">
                                    <Label className="form-label">{props.t("Note")}</Label>
                                    <Input
                                      id="note"
                                      name="note"
                                      type="textarea"
                                      rows="1"
                                      onChange={handleChange}
                                      value={validation.values.note || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      id="save"
                                      type="submit"
                                      className="btn btn-success save-patient"
                                    >
                                      {props.t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                      </React.Fragment>
                    </TabPane>
                    {openedTabs.map((openedTab, key) =>
                      (
                        <TabPane key={key} tabId={openedTab.tabIndex} id={`custDetail${openedTab.patient.id}`}>
                          <PatientDetail
                            id={openedTab.patient.id}
                            patient={openedTab.patient}
                            clinicOptions={clinicOptions}
                            genderOptions={genderOptions}
                            doctors={doctors}
                          />
                        </TabPane>
                      ))}
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </React.Fragment>
    );
  }
;
Patients.propTypes = {
  t: PropTypes.any,
  Patients: PropTypes.array,
  onAddNewPatient: PropTypes.func,
  onDeletePatient: PropTypes.func,
  onUpdatePatient: PropTypes.func,
  onGetSegments: PropTypes.func,
  segmentId: PropTypes.any
};

export default withRouter(withTranslation()(Patients));
