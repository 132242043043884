import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";

// Column
import { withTranslation } from "react-i18next";
import { AppointmentDesc, CreateDate } from "../PatientCol";
import { formatDate } from "../../../common/commonFunctions";
import CustomDatePicker from "../../../components/Common/CustomDatePicker";
import BasicTable from "../../../components/Common/BasicTable";
import Select from "react-select";
import { post } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import Notification from "../../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { addNewPatientAppointment } from "../../../store/patientAppointment/actions";

const PatientAppointment = props => {

  const dispatch = useDispatch();

  const [apptDate, setApptDate] = useState(null);
  const [apptDescription, setApptDescription] = useState("");
  const [selectedAppt, setSelectedAppt] = useState(false);
  const [custAppt, setCustAppt] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [patient, setPatient] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [clinicOptions, setClinicOptions] = useState(props.clinicOptions);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  const patientAppointmentBody = {
    size: 100,
    page: 0,
    patient: patient.id,
    status: "APPROVED"
  };

  const appointmentColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        accessor: "appointmentDate",
        filterable: true,
        className: "col-3",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Specialist")}`,
        accessor: "appointmentUser.fullName",
        filterable: true,
        className: "col-3",
        Cell: (cellProps) => {
          return <AppointmentDesc {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        accessor: "description",
        filterable: true,
        className: "col-6",
        Cell: (cellProps) => {
          return <AppointmentDesc {...cellProps} />;
        }
      }
    ],
    []
  );

  useEffect(() => {
    setPatient(props.patient);
  }, [props.patient]);

  useEffect(() => {
    setClinicOptions(props.clinicOptions);
  }, [props.clinicOptions]);

  const saveAppt = () => {
    const filteredDoctor = props.doctors && props.doctors.find(item=>item.id === selectedDoctor.value)
    const createAppt = {
      appointmentDate: formatDate(apptDate),
      description: apptDescription,
      patient: props.patient,
      appointmentUser: filteredDoctor
    };

    dispatch(addNewPatientAppointment(createAppt));
    setForceUpdate(true);
    setApptDate(null);
    setApptDescription("");
    setSelectedAppt(false);
  };

  const { error, success } = useSelector(state => ({
    error: state.PatientAppointment.error,
    success: state.PatientAppointment.success
  }));

  useEffect(() => {
    if (Object.entries(error).length > 0) {
      setShowNotification(true);
      setMessage(error.data.message);
      setNotificationType("Danger");
    } else if (success) {
      setShowNotification(true);
      setMessage(props.t("Appointment request has been received."));
      setNotificationType("Success");
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    if (forceUpdate && patient.id || patient.id) {
      post(url.GET_PATIENT_APPOINTMENT_CRITERIA, patientAppointmentBody).then(res => {
        setCustAppt(res.content);
      });
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  const handleChangeApptDescription = (e) => {
    setApptDescription(e.target.value);
  };

  const handleChangeApptDate = (e) => {
    setSelectedAppt(true);
    setApptDate(e);
  };

  function onChangeClinicSelect(event) {
    if (event !== null) {
      setSelectedClinic(event);
    } else {
      setSelectedClinic(null);
      setSelectedDoctor(null);
    }
  }

  function onChangeDoctorSelect(event) {
    if (event !== null) {
      setSelectedDoctor(event);
    } else {
      setSelectedDoctor(null);
    }
  }

  useEffect(() => {
    const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
      label: doctor.fullName,
      value: doctor.id
    }));
    setDoctorOptions(doctorList || []);
  }, [selectedClinic]);

  const callBackClose = (val) => {
    setMessage("");
    setShowNotification(val);
  };

  return (
    <React.Fragment>
      <Notification
        type={notificationType}
        message={message}
        show={showNotification}
        callbackShow={callBackClose}
      />
      <div className="row mt-3">
        <div className="col-11">
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <label>{props.t("Appointment Time")}</label>
              <CustomDatePicker
                id="apptDate"
                selected={apptDate}
                callback={handleChangeApptDate}
                showTimeSelect={true}
                showTimeSelectOnly={false}
                showTimeInput={true}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <label>{props.t("Clinic")}</label>
              <Select
                className="bs-select"
                value={selectedClinic}
                onChange={(e) => onChangeClinicSelect(e)}
                options={clinicOptions}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <label>{props.t("Specialist")}</label>
              <Select
                className="bs-select"
                value={selectedDoctor}
                onChange={(e) => onChangeDoctorSelect(e)}
                options={doctorOptions}
              />
            </div>
            <div className="col-xs-12 col-md-12">
              <label>{props.t("Description")}</label>
              <Input
                id="apptDesc"
                value={apptDescription}
                onChange={handleChangeApptDescription}
              />
            </div>
          </div>
        </div>
        <div className="col-1">
          <div className="mt-4">
            <Button
              id="saveAppt"
              type="button"
              color="success"
              onClick={saveAppt}
              disabled={!selectedAppt}
            >
              {props.t("Create")}
            </Button>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        {custAppt && custAppt.length > 0 ? (
          <BasicTable
            id="custAppt"
            customPageSize={10}
            columns={appointmentColumns}
            data={custAppt}
            className="custom-header-basic-css"
          />) : null}
      </div>
    </React.Fragment>
  );
};
PatientAppointment.propTypes = {
  doctors: PropTypes.any,
  patient: PropTypes.any,
  clinicOptions: PropTypes.any
};

export default withTranslation()(PatientAppointment);
