import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import leads from "./leads/reducer";

//users
import users from "./users/reducer";

// segment
import segments from "./segment/reducer";

// resources
import resources from "./resource/reducer";

// category
import categories from "./category/reducer";

// dashboard
import Dashboard from "./dashboard/reducer";

// roles
import roles from "./roles/reducer";

// notes
import note from "./note/reducer";

// import
import FileImport from "./import/reducer";

// reference
import references from "./reference/reducer";

// appointment
import Appointment from "./appointment/reducer";

//criteria
import Criteria from "./Criteria/reducer";

//report
import Report from "./report/reducer";

//report
import Languages from "./language/reducer";

//IntegrationDefinition
import IntegrationDefinition from "./IntegrationDefinition/reducer";

//report
import Platforms from "./platform/reducer";

// reminder
import Reminder from "./reminder/reducer";

// system parameter
import Parameter from "./parameter/reducer";

// history
import History from "./history/reducer";

// auth log
import authLog from "./authLog/reducer";

// TermsAndPrivacy
import TermsAndPolicy from "./TermsAndPrivacy/reducer";

// forms
import FacebookForms from "./FacebookForms/reducer";

//
import leadIntegrationDefs from "./leadIntegrationDefinition/reducer";

// calendar
import Calendar from "./calendar/reducer";
//Doctor
import Doctor from "./doctor/reducer";

//Hospital
import Hospital from "./hospital/reducer";

//Operation Definition
import OperationDefinition from "./operationDefinition/reducer";

//Operation Category
import OperationCategory from "./operationCategory/reducer";

//Medical Operation
import MedicalOperation from "./medicalOperation/reducer";

//Offer
import Offer from "./offer/reducer";

//Offer Item
import OfferItem from "./offerItem/reducer";

//Description Template
import DescriptionTemplate from "./descriptionTemplate/reducer";

//Firm Info
import FirmInfo from "./firmInfo/reducer";

//Screen
import Screen from "./screen/reducer";

//Screen Action
import ScreenAction from "./screenAction/reducer";

//Group
import Group from "./group/reducer";

//Patient
import patients from "./patient/reducer";

import PatientAppointment from "./patientAppointment/reducer";
import PatientReminder from "./patientReminder/reducer";
import PatientNote from "./patientNote/reducer";
import Department from "./department/reducer";
import UserWorkSchedule from "./userWorkSchedule/reducer";
import UserAvailability from "./userAvailability/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  leads,
  users,
  segments,
  resources,
  categories,
  Dashboard,
  roles,
  note,
  FileImport,
  references,
  Appointment,
  Criteria,
  Report,
  Languages,
  IntegrationDefinition,
  Platforms,
  Reminder,
  Parameter,
  History,
  TermsAndPolicy,
  FacebookForms,
  authLog,
  leadIntegrationDefs,
  Doctor,
  Hospital,
  OperationCategory,
  OperationDefinition,
  MedicalOperation,
  Offer,
  OfferItem,
  DescriptionTemplate,
  FirmInfo,
  Screen,
  ScreenAction,
  Calendar,
  Group,
  patients,
  PatientAppointment,
  PatientNote,
  PatientReminder,
  Department,
  UserWorkSchedule,
  UserAvailability
})

export default rootReducer
