import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, CardBody, Col, Collapse, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timegridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//Import Breadcrumb
//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useDispatch, useSelector } from "react-redux";
import { convertMinutesToTime, DateToString, findDoctorsAssistant, formatDate } from "../../common/commonFunctions";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { get, getWithoutToken, post } from "../../helpers/axios_with_headers";
import * as url from "../../helpers/url_helper";
import WarningModal from "../Common/WarningModal";
import { Link } from "react-router-dom";
import { getUserWorkScheduleById } from "../../store/userWorkSchedule/actions";
import { userCriteria } from "../../store/users/actions";

const authUser = JSON.parse(localStorage.getItem("authUser"));
const Calendar = props => {

  const dispatch = useDispatch();

  const locale = localStorage.getItem("I18N_LANGUAGE").toString();
  const loggedInUser = JSON.parse(localStorage.getItem("authUser"));

  const now = new Date();
  const minus10day = new Date(now.getFullYear(),
    now.getMonth(),
    now.getDay() - 10);

  const plus2month = new Date(now.getFullYear(),
    now.getMonth()+3,
    now.getDay());

  const patientAppointmentBody = {
    size: 100,
    page: 0,
    appointmentDateStart: DateToString(minus10day, "YYYY-MM-DD", "-"),
    appointmentDateEnd: DateToString(plus2month,"YYYY-MM-DD", "-"),
  };

  //CONSTANTS
  const workDayOptions = [
    { label: props.t("Monday"), value: 1, name: "MONDAY" },
    { label: props.t("Tuesday"), value: 2, name: "TUESDAY" },
    { label: props.t("Wednesday"), value: 3, name: "WEDNESDAY" },
    { label: props.t("Thursday"), value: 4, name: "THURSDAY" },
    { label: props.t("Friday"), value: 5, name: "FRIDAY" },
    { label: props.t("Saturday"), value: 6, name: "SATURDAY" },
    { label: props.t("Sunday"), value: 0, name: "SUNDAY" }
  ];

  const [eventDetail, setEventDetail] = useState({});
  const [slotDuration, setSlotDuration] = useState("00:30");
  const [slotMinTime, setSlotMinTime] = useState("09:00");
  const [slotMaxTime, setSlotMaxTime] = useState("20:00");
  const [disableTimes, setDisableTimes] = useState({});
  const [hiddenDays, setHiddenDays] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDayEnd, setSelectedDayEnd] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [events, setEvents] = useState([]);
  const [clinicOptions, setClinicOptions] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState();
  const [selectedCalendarClinic, setSelectedCalendarClinic] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [selectedCalendarDoctor, setSelectedCalendarDoctor] = useState();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [patientAppointments, setPatientAppointments] = useState([]);
  const [userAvailability, setUserAvailability] = useState([]);
  const [warningModal, setWarningModal] = useState(false);
  const [selectedWorkdays, setSelectedWorkdays] = useState();
  const [apptSettingsOpen, setIsApptSettingsOpen] = useState(false);
  const [userWorkSchedule, setUserWorkSchedule] = useState({});
  const [isDisabled, setIsDisabled] = useState(!props.external);

  useEffect(() => {
    setEvents(props.events);
  }, [props.events]);

  function checkOverlap(selected) {
    const existingEvents = events;
    const newEvent = selected;
    for (let i = 0; i < existingEvents.length; i++) {
      const existingStart = new Date(existingEvents[i].start);
      const existingEnd = new Date(existingEvents[i].end);
      const newStart = new Date(newEvent.start);
      const newEnd = new Date(newEvent.end);

      if ((newStart > existingStart && newStart < existingEnd) ||
        (newEnd > existingStart && newEnd < existingEnd) ||
        (newStart < existingEnd && existingStart < newEnd) ||
        (newStart < existingStart && newEnd > existingEnd) ||
        (newEnd.getTime() === existingStart.getTime() - 60000) ||
        (newStart.getTime() === existingEnd.getTime() - 60000)) {
        setWarningModal(true);
        return false;
      }
    }
    return true;
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEventDetail({});
      setSelectedDay("");
      setSelectedDayEnd("");
      setSelectedDoctor("");
      setSelectedPatient({});
      setFullName("");
      setPhoneNumber("");
      setSelectedClinic("");
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };

  const handleEventClick = arg => {
    setIsEdit(true);
    const event = arg.event;
    const filtered = events.filter(item => item.id && item.id.toString() === event._def.publicId)[0];

    if (props.eventComponent && props.external) {
      props.callbackEventClick(filtered);
      toggle();
    }
    setEventDetail({
      id: filtered.id,
      description: filtered.description,
      doctor: filtered.doctor,
      patient: filtered.patient,
      start: formatDate(filtered.start),
      end: formatDate(filtered.end),
      appointmentDate: formatDate(filtered.start)
    });
    setSelectedDay(formatDate(filtered.start));
    setSelectedDayEnd(formatDate(filtered.end));
    setSelectedPatient(filtered.patient);
    setPhoneNumber(filtered.patient && filtered.patient.phoneNumber || "");
    setFullName(filtered.patient && filtered.patient.fullName);
    setSelectedDoctor({ label: filtered.doctor && filtered.doctor.fullName, value: filtered.doctor });
    toggle();
  };

  useEffect(() => {
    console.log("patientAppointments", patientAppointments);
    const newEvents = [];

    if (patientAppointments) {
      patientAppointments.forEach(item => {
        const isEventExist = events.some(event => event.id && event.id === item.id);
        if (!isEventExist) {
          newEvents.push({
            id: item.id,
            title: item.patient.fullName,
            fullName: item.patient.fullName,
            phoneNumber: item.patient && item.patient.phoneNumber || "",
            patient: item.patient,
            doctor: item.appointmentUser,
            doctorId: item.appointmentUser.id,
            start: new Date(item.appointmentDate),
            end: new Date(item.appointmentEndDate),
            className: item.status === "APPROVED" ? "bg-success bg-soft text-white" : (item.status === "REJECTED" ? "bg-danger bg-soft text-white" : "bg-warning bg-soft text-white")
          });
        }
      });
    }

    setEvents([...events, ...newEvents]);
  }, [patientAppointments]);

  useEffect(() => {
    const newEvents = [];

    if (userAvailability) {
      userAvailability.forEach(item => {
        const isEventExist = item.id && events.some(event => event.id === item.id);
        if (!isEventExist || events.length === 0) {
          newEvents.push({
            id: item.id,
            type: "Leave",
            title: props.t("Leave"),
            start: new Date(item.startTime),
            end: new Date(item.endTime),
            className: "bg-secondary bg-soft text-white"
          });
        }
      });
    }

    setEvents([...events, ...newEvents]);
  }, [userAvailability]);

  const { doctors } = useSelector(state => ({
    doctors: state.users.users
  }));

  useEffect(() => {
    let userReq = Object.assign({ notRole: "ROLE_MODERATOR", status: "ACTIVE", role: "ROLE_DOCTOR" });
    dispatch(userCriteria(userReq));
  }, []);

  useEffect(() => {
    if (doctors) {
      const doctorOrAssistant = findDoctorsAssistant(doctors, loggedInUser);
      if (doctorOrAssistant) {
        if (doctorOrAssistant instanceof Object) {
          setSelectedCalendarDoctor({ label: doctorOrAssistant.fullName, value: doctorOrAssistant.id });
          setDoctorOptions([{ label: doctorOrAssistant.fullName, value: doctorOrAssistant.id }]);
        } else {
          const doctorOrAssistantOptions = doctorOrAssistant.map((doctor) => ({
            label: doctor.fullName,
            value: doctor.id
          }));
          setDoctorOptions(doctorOrAssistantOptions);
        }
      }
    }
  }, [doctors, props.dashboard, props.external]);

  useEffect(() => {
    if (props.callbackCloseModal) {
      toggle();
    }
  }, [props.callbackCloseModal]);

  useEffect(() => {
    if (props.dashboard || props.external) {
      if (doctors.length > 0) {
        const doctorList = doctors.map((doctor) => ({
          label: doctor.fullName,
          value: doctor.id
        }));
        setDoctorOptions(doctorList);
      }
    }
  }, [props.dashboard, props.external]);


  function handleSelect(arg) {
    const convertedDateStart = formatDate(arg.startStr);
    const convertedDateEnd = formatDate(arg.endStr);
    setSelectedDay(convertedDateStart);
    setSelectedDayEnd(convertedDateEnd);
    if (props.external) {
      props.callbackSelectedDateStart(convertedDateStart);
      props.callbackSelectedDateEnd(convertedDateEnd);
    }
    toggle();
  }

  useEffect(() => {
    if (selectedDayEnd !== "" && selectedDay !== "") {
      checkOverlap({ start: selectedDay, end: selectedDayEnd });
    }
  }, [selectedDay, selectedDayEnd]);

  function handleChangeInput(e) {
    if (e.target.name === "fullName") {
      setFullName(e.target.value);
    } else if (e.target.name === "phoneNumber") {
      setPhoneNumber(e.target.value);
    }
  }

  function onChangeClinic(event) {
    setSelectedClinic(event);
  }

  function onChangeDoctor(event) {
    setSelectedDoctor(event);
  }

  function onChangeCalendarClinic(event) {
    setSelectedCalendarClinic(event);
    setSelectedCalendarDoctor(null);
    setEvents([]);
  }

  function onChangeCalendarDoctor(event) {
    setEvents([]);
    setSelectedCalendarDoctor(event);
  }

  useEffect(() => {
    getWithoutToken(process.env.REACT_APP_API_URL + "/userGroup/findHighAuthUsersWithDepartment").then(response => {
      if (response.status === 200) {
        const clinicList = response.data.length > 0 && response.data.map(item => ({
          label: item.department,
          value: item
        }));
        setClinicOptions(clinicList);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedClinic && selectedClinic.value) {
      const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
        label: doctor.fullName,
        value: doctor.id
      }));
      setDoctorOptions(doctorList);
    }
  }, [selectedClinic]);

  useEffect(() => {
    if (!props.dashboard) {
      const doctorList = selectedCalendarClinic && selectedCalendarClinic.value.users && selectedCalendarClinic.value.users.map((doctor) => ({
        label: doctor.fullName,
        value: doctor.id
      }));
      setDoctorOptions(doctorList);
    }
  }, [selectedCalendarClinic, props.dashboard]);

  function saveAppointment() {
    if (isEdit) {
      const req = {
        ...eventDetail,
        patient: selectedPatient,
        appointmentUser: selectedDoctor && selectedDoctor.value,
        appointmentDate: selectedDay,
        appointmentEndDate: selectedDayEnd
      };
      post(url.ADD_NEW_PATIENT_APPOINTMENT, req).then(response => {
        if (response.status === 200) {
          const mapped = {
            id: response.data.id,
            title: response.data.patient.fullName,
            fullName: response.data.patient.fullName,
            phoneNumber: response.data.patient && response.data.patient.phoneNumber || "",
            patient: response.data.patient,
            doctor: response.data.appointmentUser,
            doctorId: response.data.appointmentUser.id,
            start: new Date(response.data.appointmentDate),
            end: new Date(response.data.appointmentEndDate),
            className: "bg-warning bg-soft text-white"
          };
          const newMapped = events.map(appt =>
            appt.id && appt.id.toString() === response.data.id.toString()
              ? { events, ...mapped }
              : appt
          );
          setEvents(newMapped);
        }
      });
    } else {
      const req = {
        startTime: selectedDay,
        endTime: selectedDayEnd,
        fullName: fullName,
        phoneNumber: phoneNumber,
        appointmentDate: selectedDay,
        doctorId: selectedDoctor && selectedDoctor.value
      };
      post(url.SAVE_WITH_APPT, req).then(response => {
        if (response.status === 200) {
          const mapped = {
            id: response.data.id,
            title: response.data.patient.fullName,
            fullName: response.data.patient.fullName,
            phoneNumber: response.data.patient && response.data.patient.phoneNumber || "",
            patient: response.data.patient,
            doctor: response.data.appointmentUser,
            doctorId: response.data.appointmentUser.id,
            start: new Date(response.data.appointmentDate),
            end: new Date(response.data.appointmentEndDate),
            className: "bg-warning bg-soft text-white"
          };
          setEvents([...events, mapped]);
        }
      });
    }
    toggle();
  }

  function getCalendar() {
    const req = Object.assign({
      ...patientAppointmentBody,
      appointmentUser: selectedCalendarDoctor.value
    });

    console.log("req", req);
    post(url.GET_PATIENT_APPOINTMENT_CRITERIA, req).then(response => setPatientAppointments(response.data.content));
    get(`${url.AVAILABILTY_API_BY_ID}/${selectedCalendarDoctor.value}`).then(response => setUserAvailability(response.data));
  }

  useEffect(() => {
    if (props.external && authUser && authUser.id && authUser.role.name !== "ROLE_DOCTOR" || selectedCalendarDoctor && selectedCalendarDoctor.value) {
      const doctorId = props.external && authUser.role.name !== "ROLE_DOCTOR" ? authUser.id : selectedCalendarDoctor && selectedCalendarDoctor.value;
      dispatch(getUserWorkScheduleById(doctorId));
    }
  }, [authUser, props.external, selectedCalendarDoctor && selectedCalendarDoctor.value]);

  const { schedule } = useSelector(state => ({
    schedule: state.UserWorkSchedule.userWorkSchedules
  }));

  useEffect(() => {
    if (schedule && Object.entries(schedule).length > 0 && selectedCalendarDoctor) {
      setUserWorkSchedule(schedule);
      if (schedule.workDays !== undefined) {
        const days = schedule.workDays && workDayOptions.filter((option) => !schedule.workDays.some((day) => day === option.name));
        const offDays = days.map(item => item.value);
        setHiddenDays(offDays);
      }
      if (schedule.breakTimeStart !== undefined && schedule.breakTimeEnd !== undefined) {
        const disObject = { start: schedule.breakTimeStart, end: schedule.breakTimeEnd };
        setDisableTimes(disObject);
      }

      setSlotMinTime(schedule.workTimeStart);
      setSlotMaxTime(schedule.workTimeEnd);

      if (schedule.period) {
        const converted = convertMinutesToTime(schedule.period);
        setSlotDuration(converted);
      }
      if (schedule.workDays) {
        const days = workDayOptions && workDayOptions.filter((option) => schedule.workDays.some((day) => day === option.name));
        setSelectedWorkdays(days);
      }
    } else if (!schedule || !selectedCalendarDoctor) {
      setUserWorkSchedule({});
      setDisableTimes({});
      setHiddenDays([]);
      setSlotMinTime("09:00:00");
      setSlotMaxTime("20:00:00");
      setSlotDuration("00:30");
      setSelectedWorkdays([]);
    }
  }, [schedule, selectedCalendarDoctor]);

  const toggleAppt = () => setIsApptSettingsOpen(!apptSettingsOpen);

  function handleSelectedWorkdayOptions(option) {
    const days = option.map((item) => item.name);
    setSelectedWorkdays(option);
    setUserWorkSchedule({
      ...userWorkSchedule,
      ["workDays"]: days
    });
  }

  const handleChangeSettings = (e) => {
    setUserWorkSchedule({
      ...userWorkSchedule,
      [e.target.name]: e.target.value
    });
  };

  function handleChangeStart(e) {
    const convertedDate = formatDate(e.target.value);
    setSelectedDay(convertedDate);
  }

  function handleChangeEnd(e) {
    const convertedDate = formatDate(e.target.value);
    setSelectedDayEnd(convertedDate);
  }

  function handleSaveChanges() {
    props.saveChanges(userWorkSchedule);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col className="col-12">
            <Row className="p-2">
              <div className="custom-accordion">
                <Link
                  className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
                  onClick={toggleAppt}
                  to="#"
                >
                  <i className="mdi mdi-calendar-account font-size-20 text-secondary"></i>{" "}
                  {props.t("Calendar Settings")}
                  <i
                    className={
                      apptSettingsOpen
                        ? "mdi mdi-chevron-up accor-down-icon"
                        : "mdi mdi-chevron-down accor-down-icon"
                    }
                  />
                </Link>
                <Collapse isOpen={apptSettingsOpen}>
                  <div className="card border-1 shadow-none mb-0">
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="row">
                            <div className="col-md-3">
                              <Label className="form-label">{props.t("Work Time Start")}</Label>
                              <Input
                                name="workTimeStart"
                                type="time"
                                onChange={handleChangeSettings}
                                value={userWorkSchedule.workTimeStart || ""}
                                disabled={isDisabled}
                              />
                            </div>
                            <div className="col-md-3">
                              <Label className="form-label">{props.t("Work Time End")}</Label>
                              <Input
                                name="workTimeEnd"
                                type="time"
                                onChange={handleChangeSettings}
                                value={userWorkSchedule.workTimeEnd || ""}
                                disabled={isDisabled}
                              />
                            </div>
                            <div className="col-md-3">
                              <Label className="form-label">{props.t("Break Time Start")}</Label>
                              <Input
                                name="breakTimeStart"
                                type="time"
                                onChange={handleChangeSettings}
                                value={userWorkSchedule.breakTimeStart || ""}
                                disabled={isDisabled}
                              />
                            </div>
                            <div className="col-md-3">
                              <Label className="form-label">{props.t("Break Time End")}</Label>
                              <Input
                                name="breakTimeEnd"
                                type="time"
                                onChange={handleChangeSettings}
                                value={userWorkSchedule.breakTimeEnd || ""}
                                disabled={isDisabled}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <Label className="form-label">{props.t("Timeline Period (Minutes)")}</Label>
                              <Input
                                name="period"
                                type="number"
                                onChange={handleChangeSettings}
                                value={userWorkSchedule.period || ""}
                                disabled={isDisabled}
                              />
                            </div>
                            <div className="col-md-9">
                              <Label className="form-label">{props.t("Work Days")}</Label>
                              <Select
                                value={selectedWorkdays}
                                isMulti={true}
                                onChange={(event) => {
                                  handleSelectedWorkdayOptions(event);
                                }}
                                placeholder={props.t("Select")}
                                options={workDayOptions}
                                className="bs-select"
                                isDisabled={isDisabled}
                              />
                            </div>
                          </div>
                          <div className="row">
                            {props.external && (
                              <div className="mt-4 text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  onClick={handleSaveChanges}
                                >
                                  {props.t("Save Settings")}
                                </button>
                              </div>)}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Collapse>
              </div>
            </Row>
            {props.external && selectedCalendarDoctor && doctorOptions && doctorOptions.length > 1 && (
              <Row className="p-2">
                <hr />
                <div className="text-start">
                  <div className="row">
                    <div className="col-md-3">
                      <Label className="form-label">{props.t("Specialist")}</Label>
                      <Select
                        value={selectedCalendarDoctor}
                        onChange={(event) => onChangeCalendarDoctor(event)}
                        options={doctorOptions}
                        className="bs-select"
                        isClearable={true}
                      />
                    </div>
                    <div
                      className="col-md-9"><Label
                      className="mt-2">{props.t("Please select specialist to see his/her Calendar")}</Label>
                    </div>
                  </div>
                </div>
              </Row>
            )}
            <Card>
              <WarningModal
                show={warningModal}
                onApproveClick={() => setWarningModal(false)}
                onCloseClick={() => setWarningModal(false)}
                title={props.t("Warning")}
                message={props.t("Your select contains unselectable dates.Please select another date and time")}
              />
              <CardBody>
                <Row>
                  {!props.external && (
                    <Col className="col-lg-2">
                      <div className="mt-5 d-none d-xl-block">
                        <h5 className="text-center">{props.t("Event Types Info")}</h5>
                        <ul className="ps-3">
                          <li className="text-muted mb-3">
                              <span
                                className="badge rounded-pill bg-secondary  bg-soft font-size-14">{props.t("Off Days")}</span>
                          </li>
                          <li className="text-muted mb-3">
                              <span
                                className="badge rounded-pill bg-success bg-soft font-size-14">{props.t("Approved Appointments")}</span>
                          </li>
                          <li className="text-muted mb-3">
                              <span
                                className="badge rounded-pill bg-warning bg-soft font-size-14">{props.t("Waiting Appointments")}</span>
                          </li>
                          <li className="text-muted mb-3">
                              <span
                                className="badge rounded-pill bg-danger bg-soft font-size-14">{props.t("Rejected Appointments")}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-5 d-none d-xl-block">
                        <div className="mb-2">
                          <Label className="form-label">{props.t("Clinic")}</Label>
                          <Select
                            value={selectedCalendarClinic}
                            onChange={(event) => onChangeCalendarClinic(event)}
                            options={clinicOptions}
                            className="bs-select"
                            isClearable={true}
                            isDisabled={props.dashboard}
                          />
                        </div>
                        <div className="mb-2">
                          <Label className="form-label">{props.t("Specialist")}</Label>
                          <Select
                            value={selectedCalendarDoctor}
                            onChange={(event) => onChangeCalendarDoctor(event)}
                            options={doctorOptions}
                            className="bs-select"
                            isClearable={true}
                          />
                        </div>
                        <div className="mb-2 text-center">
                          <button
                            id="save"
                            type="submit"
                            className="btn btn-outline-success"
                            onClick={() => getCalendar()}
                          >
                            {props.t("Get Calendar Events")}
                          </button>
                        </div>
                      </div>
                    </Col>)}
                  <Col className={props.external ? "col-lg-12" : "col-lg-10"}>
                    <FullCalendar
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                        timegridPlugin
                      ]}
                      themeSystem="bootstrap"
                      initialView="timeGridWeek"
                      slotDuration={slotDuration}
                      handleWindowResize={true}
                      expandRows={true}
                      displayEventTime={true}
                      displayEventEnd={true}
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay"
                      }}
                      buttonText={{
                        today: props.t("Today"),
                        month: props.t("Month"),
                        week: props.t("Week"),
                        day: props.t("Day")
                      }}
                      selectConstraint={disableTimes}
                      allDaySlot={false}
                      slotMinTime={slotMinTime || "07:30"}
                      slotMaxTime={slotMaxTime || "22:00"}
                      //  dayHeaderFormat={{ weekday: "long", month: "numeric", day: "numeric", omitCommas: false }}
                      locales="allLocales"
                      locale={locale || "en"}
                      firstDay={1} //Pazartesi manasında
                      hiddenDays={hiddenDays}// hide Tuesdays and Thursdays
                      weekends={true}
                      events={events}
                      eventResizableFromStart={true}
                      editable={false}
                      droppable={false}
                      selectable={true}
                      //allDayContent={}
                      eventClick={handleEventClick}
                      // eventAllow={selectAllow}
                      select={handleSelect}
                      selectAllow={checkOverlap}
                      eventOverlap={false}
                    />

                    {/* New/Edit event modal */}
                    <Modal isOpen={modal} className={props.className}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {isEdit ? props.t("Event Detail") : props.t("New Event")}
                      </ModalHeader>
                      <ModalBody>
                        {props.external ? (
                          props.eventComponent
                        ) : (
                          <Row className="m-2">
                            <Col className="col-12">
                              <div className="mb-2">
                                <Label className="form-label">{props.t("Name")}</Label>
                                <Input
                                  id="fullName"
                                  name="fullName"
                                  type="text"
                                  onChange={handleChangeInput}
                                  value={fullName || ""}
                                />
                              </div>
                              <div className="mb-2">
                                <Label className="form-label">{props.t("Phone")}</Label>
                                <Input
                                  id="phoneNum"
                                  name="phoneNumber"
                                  type="text"
                                  onChange={handleChangeInput}
                                  value={phoneNumber || ""}
                                />
                              </div>
                              <div className="mb-2">
                                <Label className="form-label">{props.t("Clinic")}</Label>
                                <Select
                                  value={selectedClinic}
                                  onChange={(event) => onChangeClinic(event)}
                                  options={clinicOptions}
                                  className="bs-select"
                                  isClearable={true}
                                />
                              </div>
                              <div className="mb-2">
                                <Label className="form-label">{props.t("Specialist")}</Label>
                                <Select
                                  value={selectedDoctor}
                                  onChange={(event) => onChangeDoctor(event)}
                                  options={doctorOptions}
                                  className="bs-select"
                                  isClearable={true}
                                />
                              </div>
                              <Col className="col-12 mb-3">
                                <Label className="form-label">{props.t("Start")}</Label>
                                <Input
                                  name="start"
                                  type="datetime-local"
                                  onChange={handleChangeStart}
                                  value={selectedDay || ""}
                                />
                              </Col>
                              <Col className="col-12 mb-3">
                                <Label className="form-label">{props.t("End")}</Label>
                                <Input
                                  name="end"
                                  type="datetime-local"
                                  onChange={handleChangeEnd}
                                  value={selectedDayEnd || ""}
                                />
                              </Col>
                            </Col>
                            <Row className="m-2">
                              <Col>
                                <div className="text-end mt-2">
                                  <button
                                    id="save"
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={() => saveAppointment()}
                                  >
                                    {props.t("Save")}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

Calendar.propTypes = {
  t: PropTypes.any,
  events: PropTypes.array,
  className: PropTypes.string,
  slotMinTime: PropTypes.string,
  slotMaxTime: PropTypes.string,
  slotDuration: PropTypes.string,
  external: PropTypes.bool,
  callbackFunction: PropTypes.func,
  disableTimes: PropTypes.object,
  calendarEventCallback: PropTypes.func,
  eventComponent: PropTypes.any,
  callbackSelectedDateStart: PropTypes.func,
  callbackSelectedDateEnd: PropTypes.func,
  callbackCloseModal: PropTypes.bool,
  callbackEventClick: PropTypes.func,
  saveChanges: PropTypes.func,
  dashboard: PropTypes.bool
};

export default withTranslation()(Calendar);
