import React, { useEffect, useState } from "react";

import { Badge, Col, Collapse, Input, InputGroup, Label, Row } from "reactstrap";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { getWithoutToken, postWithoutToken } from "../../helpers/axios_with_headers.js";
import { phoneCodes } from "../../constants/phoneCodesTurkish";
import ReCAPTCHA from "react-google-recaptcha";
import { phoneOptions } from "../../constants/phoneCodes";
import axios from "axios";
import Select from "react-select";
import classnames from "classnames";

// import images

const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
const WebFormAppointment = props => {

  //meta title
  document.title = "Web Form";

  const [fullName, setFullName] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("09:00");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [responseCode, setResponseCode] = useState(null);
  const [phoneCode, setPhoneCode] = useState(phoneOptions[0]);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [disableButton, setIsDisableButton] = useState(true);
  const [sendCodeButtonDisabled, setSendCodeButtonDisabled] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [clinicOptions, setClinicOptions] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [apptArray, setApptArray] = useState([]);
  const [selectedHourIndex, setSelectedHourIndex] = useState("");
  const [selectedDateIndex, setSelectedDateIndex] = useState("");

  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    switch (e.target.name) {
      case "fullName":
        setFullName(e.target.value);
        break;
      case "date":
        setDate(e.target.value);
        break;
      case "time":
        setTime(e.target.value);
        break;
    }
  };

  useEffect(() => {
    getDoctorsAndClinics();
  }, []);

  function getDoctorsAndClinics() {
    getWithoutToken(process.env.REACT_APP_API_URL + "/userGroup/findHighAuthUsersWithDepartment").then(response => {
      if (response.status === 200) {
        const clinicList = response.data.map(item => ({ label: item.department, value: item }));
        setClinicOptions(clinicList);
      }
    });
  }

  useEffect(() => {
    const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
      label: doctor.fullName,
      value: doctor.id
    }));
    setDoctorOptions(doctorList||[]);
  }, [selectedClinic]);

  const onChangePhoneCode = (e) => {
    setPhoneCode({ label: e.target.label, value: e.target.value });
  };

  const handleChangePhone = (e) => {
    let str = e.target.value.toString();
    let phoneCode = "";
    let tel = "";
    if (str.includes("+")) {
      phoneCode = str.split(/\s/)[0];
      findPhoneCode(phoneCode);
      tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
    } else {
      tel = str.replaceAll(/\s+/g, "");
    }
    setPhoneNumber(tel);
  };

  const findPhoneCode = (code) => {
    phoneOptions.forEach(cd => {
      if (cd.value === code)
        setPhoneCode(cd);
    });
  };

  useEffect(() => {
    const handle = setInterval(() => {
      setSendCodeButtonDisabled(false);
    }, 60000);
    return () => {
      clearInterval(handle);
    };
  }, []);

  useEffect(() => {
    const dis = isCaptchaSuccessful && fullName !== "" && phoneNumber !== "" && date !== "" && time !== "";
    setIsDisableButton(!dis);
  }, [isCaptchaSuccessful, fullName, phoneNumber]);

  const sendFormDataToBackend = () => {
    const createLeadReq = {
      secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
      fullName: fullName,
      phoneNumber: phoneCode.value + phoneNumber,
      appointmentDate: date + " " + time,
      doctorId: selectedDoctor.value
    };

    postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/receivePotentialPatientFromWebsite", createLeadReq).then(r => {
      if (r.status === 200) {
        setIsSent(true);
        setPhoneNumber("");
        setFullName("");
        setAppointmentDate("");
        setPhoneCode(phoneCodes[0]);
        setIsCaptchaSuccess(false);
        setIsDisableButton(true);
        recaptchaRef.current.reset();
      }
    });
  };

  const [cols, setCols] = useState([true, false, false, false, false, false, false]);

  const t_col = (index) => {
    const updatedCols = Array(cols.length).fill(false);
    updatedCols[index] = true;
    setCols(updatedCols);
  };

  const getAppointmentDataFromBackend = (id) => {
    const getReq = {
      secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
      doctorId: id
    };

    postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/findAvailableTimes", getReq).then(response => {
      if (response.status === 200 && response.data) {
        setApptArray(response.data);
      }
    });
  };

  function onCaptchaChange(value) {
    setIsCaptchaSuccess(true);
  }

  function onChangeClinic(event) {
    if (event !== null) {
      setSelectedClinic(event);
    }else{
      setSelectedClinic(null)
      setSelectedDoctor(null)
      setApptArray([])
    }
  }

  function onChangeDoctor(event) {
    if (event !== null) {
      setSelectedDoctor(event);
      getAppointmentDataFromBackend(event.value);
    }else{
      setSelectedDoctor(null)
      setApptArray([])
    }
  }

  function handleSelectedApptInfo(hour, data, hourIndex, dateIndex) {
    setSelectedHourIndex(hourIndex);
    setSelectedDateIndex(dateIndex);
    setDate(data);
    setTime(hour);
  }

  return (
    <div className="page-content-centered">
      <div>
        {!isSent ? (
          <Col xl={12} md={12} xs={12}>
            <Row className="m-2">
              <Col className="col-12">
                <div className="mb-2">
                  <Label className="form-label">{"Name"}</Label>
                  <Input
                    id="fullName"
                    name="fullName"
                    type="text"
                    onChange={handleChange}
                    value={fullName || ""}
                    invalid={!fullName}
                    required={true}
                  />
                </div>
                <div className="mb-2">
                  <Label className="form-label">{"Phone"}</Label>
                  <InputGroup>
                    <select
                      id="phoneCode"
                      className="form-select"
                      value={phoneCode.value}
                      onChange={onChangePhoneCode}
                    >
                      {phoneOptions.map(code => (
                        <option id={code.value} key={code.label} value={code.value}>
                          {code.label}
                        </option>
                      ))
                      }
                    </select>
                    <Input
                      id="phoneNum"
                      name="phoneNumber"
                      type="text"
                      onChange={handleChangePhone}
                      value={phoneNumber || ""}
                      required={true}
                      placeholder="--- --- ----"
                      invalid={!phoneNumber}
                    />
                  </InputGroup>
                </div>
                <div className="mb-2">
                  <Label className="form-label">{"Clinic"}</Label>
                  <Select
                    value={selectedClinic}
                    onChange={onChangeClinic}
                    options={clinicOptions}
                    className="bs-select"
                    isClearable={true}
                  />
                </div>
                <div className="mb-2">
                  <Label className="form-label">{"Specialist"}</Label>
                  <Select
                    value={selectedDoctor}
                    onChange={onChangeDoctor}
                    options={doctorOptions}
                    className="bs-select"
                    isClearable={true}
                  />
                </div>
                {/*
                 <div className="mb-2">
                  <div className="row">
                    <div className="col-6">
                      <Label className="form-label">{"Date"}</Label>
                      <Input
                        id="appointmentDate"
                        name="date"
                        type="date"
                        onChange={handleChange}
                        value={date}
                      />
                    </div>
                    <div className="col-6">
                      <Label className="form-label">{"Time"}</Label>
                      <Input
                        id="time"
                        name="time"
                        type="time"
                        onChange={handleChange}
                        value={time}
                        required={false}
                      />
                    </div>
                  </div>
                </div>*/}
              </Col>
            </Row>
            <Row className="m-2">
              {apptArray.map((event, index) => (
                <div key={index}>
                  <button
                    key={index}
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      { collapsed: !cols[index] }
                    )}
                    type="button"
                    onClick={() => t_col(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="font-size-14">{event.date}</span></button>
                  <Collapse isOpen={cols[index]} className="accordion-collapse">
                    <div className="accordion-body">
                      <div>
                        {event.hours.map((hour, hourIndex) => (
                          <Badge
                            key={hourIndex}
                            pill
                            className={selectedHourIndex === hourIndex && selectedDateIndex === index ? "rounded-pill badge-soft-info me-1" : "rounded-pill badge-soft-secondary me-1"}
                          >
                            <button
                              key={hourIndex}
                              className={classnames(
                                "accordion-button",
                                "fw-medium")}
                              type="button"
                              onClick={() => handleSelectedApptInfo(hour, event.date, hourIndex, index)}
                              style={{ cursor: "pointer" }}
                            >
                              <span>{hour.slice(0, -3)}</span>
                            </button>
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </Collapse>
                  <hr className="m-1" />
                </div>
              ))}
            </Row>
            <Row className="m-2">
              <Col className="col"></Col>
              <Col className="col">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  name="recaptcha"
                  id="recaptcha"
                  sitekey={siteKey}
                  onChange={onCaptchaChange}
                  onExpired={() => {
                    recaptchaRef.current.reset(); // here
                  }}
                  theme="light"
                />
              </Col>
              <Col className="col"></Col>
            </Row>
            <Row className="m-2">
              <Col>
                <div className="text-end mt-2">
                  <button
                    // disabled={disableButton || sendCodeButtonDisabled}
                    id="save"
                    type="submit"
                    className="btn btn-success"
                    onClick={() => sendFormDataToBackend()}
                  >
                    {"Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>) : (
          <div>
            <p className="text-sm-center">
              <i className="mdi mdi-check-bold text-success display-1" />
              <p className="text-info font-size-24 fw-bold">Thank you</p>
              <p className="text-dark font-size-16 fw-bold mt-2">We have received your application.</p>
              <p className="text-secondary font-size-8 mt-0">We will get back to you as soon as possible.</p>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

WebFormAppointment.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(WebFormAppointment));