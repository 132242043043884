import React, { useEffect, useState } from "react";
import { Button, Collapse, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Notification from "../../../components/Notification";
import { post } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import LeadAppointment from "./LeadAppointment";
import LeadReminder from "./LeadReminder";
import LeadNote from "./LeadNote";
import LeadOffer from "./LeadOffer";
import LeadOperation from "./LeadOperation";
import CustomLightBox from "../../../components/Common/CustomLightBox";
import FormUpload from "../../../components/Common/FormUpload";
import { useDispatch, useSelector } from "react-redux";
import { updateLead as onUpdateLead } from "../../../store/leads/actions";
import { addNewPatient } from "../../../store/patient/actions";
import { isEmpty } from "lodash";

const isAgent = process.env.REACT_APP_IS_AGENT === "TRUE";
const LeadDetail = props => {
  const dispatch = useDispatch();

  const [lead, setLead] = useState(props.lead);

  const [resource, setResource] = useState({});
  const [segment, setSegment] = useState({});
  const [category, setCategory] = useState({});
  const [salesRepresentative, setSalesRepresentative] = useState({});
  const [reference, setReference] = useState({});
  const [gender, setGender] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [phoneWhatsapp, setPhoneWhatsapp] = useState(`https://api.whatsapp.com/send/?phone=${props.lead.phoneNumber !== null ? (props.lead.phoneNumber.replace("+", "")) : ""}`);
  const [phoneTelegram, setPhoneTelegram] = useState(`https://t.me/${props.lead.phoneNumber !== null ? (props.lead.phoneNumber) : ""}`);
  const [phoneCall, setPhoneCall] = useState(`tel:${props.lead.phoneNumber !== null ? (props.lead.phoneNumber) : ""}`);
  const [isOpen, setIsOpen] = useState(false);
  const [callbackClose, setCallbackClose] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [facebookForm, setFacebookForm] = useState();
  const [reminderOpen, setIsReminderOpen] = useState(false);
  const [apptOpen, setIsApptOpen] = useState(false);
  const [offerOpen, setOfferOpen] = useState(false);
  const [operationOpen, setOperationOpen] = useState(false);
  const [mailValid, setMailValid] = useState(true);
  const [mail, setMail] = useState(props.lead.email || "");
  const [idModalOpen, setIdModalOpen] = useState(false);
  const [idNumber, setIdNumber] = useState("");

  const togglePhoto = () => setIsOpen(!isOpen);
  const toggleReminder = () => setIsReminderOpen(!reminderOpen);
  const handleOpenModal = () => setIsModalOpen(!isModalOpen);
  const toggleAppt = () => setIsApptOpen(!apptOpen);
  const toggleOffer = () => setOfferOpen(!offerOpen);
  const toggleOperation = () => setOperationOpen(!operationOpen);

  useEffect(() => {
    setLead(props.lead);
  }, [props.lead.id]);

  useEffect(() => {
      if (lead.segment) {
        setSegment({ label: lead.segment.name, value: lead.segment });
      }
    },
    [lead.segment]
  );

  useEffect(() => {
      if (lead.category) {
        setCategory({ label: lead.category.name, value: lead.category });
      }
    },
    [lead.category]
  );

  useEffect(() => {
      if (lead.resource) {
        setResource({ label: lead.resource.name, value: lead.resource });
      }
    },
    [lead.resource]
  );

  useEffect(() => {
      if (lead.salesRepresentative) {
        setSalesRepresentative(({ label: lead.salesRepresentative.fullName, value: lead.salesRepresentative }));
      }
    },
    [lead.salesRepresentative]
  );

  useEffect(() => {
      if (lead.reference) {
        setReference(({ label: lead.reference.name, value: lead.reference }));
      }
    },
    [lead.reference]
  );

  useEffect(() => {
      if (lead.facebookForm) {
        setFacebookForm(lead.facebookForm);
      }
    },
    [lead.facebookForm]
  );

  useEffect(() => {
      if (lead.gender) {
        setGender({ label: props.t(lead.gender), value: lead.gender });
      }
    },
    [lead.gender]
  );

  const { error, processResponse } = useSelector(state => ({
    error: state.patients.error,
    processResponse: state.patients.processResponse
  }));

  const handleChange = (e) => {
    const regExpMail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regExpText = /^(^$)|^[a-zA-Z çşğıÇŞĞIİÜüÖö]+$/;
    const regExpPhone = /^\d+$/;

    switch (e.target.type) {
      case "textarea":
        setLead({
          ...lead,
          [e.target.name]: e.target.value
        });
        break;
      case "tel":
        if (regExpPhone.test(e.target.value.replaceAll("+", ""))) {
          setLead({
            ...lead,
            ["phoneNumber"]: e.target.value
          });
        }
        break;
      default :
        setLead({
          ...lead,
          [e.target.name]: e.target.value
        });
        break;
    }

  };

  const handleChangeMail = (e) => {
    const regEXpMail = /^\S+@\S+\.\S+$/;
    setMailValid(false);
    const mail = e.target.value.trim();
    setMail(mail);
    if (regEXpMail.test(mail)) {
      setMailValid(true);
      setLead({
        ...lead,
        ["email"]: mail
      });
    }
  };

  const updateLead = () => {
    const updateLead = Object.assign({
      ...lead,
      id: lead ? lead.id : 0,
      firstName: lead.firstName,
      lastName: lead.lastName,
      phoneNumber: lead.phoneNumber,
      email: lead.email,
      cityName: lead.cityName,
      createDate: lead.createDate,
      birthday: lead.birthday,
      reference: lead.reference,
      salesRepresentative: lead.salesRepresentative,
      segment: lead.segment,
      category: lead.category,
      resource: lead.resource
    });
    // update lead
    dispatch(onUpdateLead(updateLead));
  };

  const sendFileToUpload = (files) => {
    const request = Object.assign({
      leadId: lead.id,
      photos: files.map(file => file.convertedBase64)
    });
    post(url.SAVE_IMAGES, request).then(response => {
      if (response && response.status === 200) {
        setMessage("Başarılı");
        setNotificationType("Success");
        setShowNotification(true);
      } else if (response && response.status === 500) {
        setMessage("Kaydedilemedi");
        setNotificationType("Danger");
        setShowNotification(true);
      }
      handleOpenModal();
    });
  };

  useEffect(() => {
    if (!isEmpty(error)) {
      setShowNotification(true);
      setMessage(error.message);
      setNotificationType("Danger");
    } else if (processResponse) {
      setShowNotification(true);
      setMessage(props.t("Success"));
      setNotificationType(props.t("Success"));
    }
  }, [error, processResponse]);

  const onChangeInResourceSelect = event => {
    setLead({
      ...lead,
      ["resource"]: event !== null ? event.value : null
    });
    setResource((event));
  };

  const onChangeInCategorySelect = event => {
    setLead({
      ...lead,
      ["category"]: event !== null ? event.value : null
    });
    setCategory((event));
  };

  const onChangeInSegmentSelect = event => {
    setLead({
      ...lead,
      ["segment"]: event !== null ? event.value : null
    });
    setSegment((event));
  };

  const onChangeInRepresentSelect = event => {
    setLead({
      ...lead,
      ["salesRepresentative"]: event !== null ? event.value : null
    });
    setSalesRepresentative((event));
  };

  const onChangeInReferenceSelect = event => {
    setLead({
      ...lead,
      ["reference"]: event !== null ? event.value : null
    });
    setReference((event));
  };

  const onChangeInGenderSelect = event => {
    setLead({
      ...lead,
      ["gender"]: event !== null ? event.value : null
    });
    setGender((event));
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  function createPatient() {
    const leadToPatientRequest = Object.assign({
      firstName: lead.firstName,
      lastName: lead.lastName,
      phoneNumber: lead.phoneNumber,
      email: lead.email,
      cityName: lead.cityName,
      birthday: lead.birthday,
      lead: lead
    });
    dispatch(addNewPatient(leadToPatientRequest));
  }

  return (
    <React.Fragment>
      <Modal isOpen={isModalOpen} toggle={handleOpenModal} centered={true}>
        <ModalHeader toggle={handleOpenModal} tag="h4"></ModalHeader>
        <ModalBody>
          <FormUpload
            id="fileForm"
            sendFile={sendFileToUpload}
            callbackClose={callbackClose}
          />
        </ModalBody>
      </Modal>
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <div className="container-fluid">
        <div className="mt-2">
          <div className="row">
            <div className="col-md-2 col-xs-4 d-inline-flex">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleOpenModal}
                disabled={true}
              >
                <i className="mdi mdi-plus me-1" />{props.t("Add Photo")}</Button>
            </div>
            <div className="col-md-8 col-xs-4 d-inline-flex"></div>
            <div className="col-md-2 col-xs-4 d-inline-flex">
              {props.lead.phoneNumber !== null && (
                <React.Fragment>
                  <a
                    className="social-platform-list-item bg-warning text-white border-warning"
                    href={phoneCall}
                    aria-label="phone"
                  >
                    <i className="mdi mdi-phone font-size-22 m-2" />
                  </a>
                  <a
                    className="social-platform-list-item bg-info text-white border-info"
                    href={phoneTelegram}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="telegram"
                  >
                    <i className="mdi mdi-telegram font-size-22 m-2" />
                  </a>
                  <a
                    className="social-platform-list-item bg-success text-white border-success"
                    href={phoneWhatsapp}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="whatsapp"
                  >
                    <i className="mdi mdi-whatsapp font-size-22 m-2 " />
                  </a>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Firstname")}</Label>
              <Input
                id="detailFirstName"
                name="firstName"
                type="text"
                onChange={handleChange}
                value={lead.firstName || ""}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Lastname")}</Label>
              <Input
                id="detailLastName"
                name="lastName"
                type="text"
                onChange={handleChange}
                value={lead.lastName || ""}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Phone")}</Label>
              <Input
                id="detailPhone"
                name="phoneNumber"
                type="tel"
                onChange={handleChange}
                value={lead.phoneNumber || ""}
                required={true}
                placeholder="(---) --- ----"
                invalid={!lead.phoneNumber}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Email")}</Label>
              <Input
                name="email"
                label="Email"
                type="email"
                onChange={handleChangeMail}
                value={mail}
                invalid={(mail.length > 0 && !mailValid)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4 col-md-2 mb-3">
              <Label className="form-label">{props.t("City")}</Label>
              <Input
                id="detailCityName"
                name="cityName"
                type="text"
                onChange={handleChange}
                value={lead.cityName || ""}
              />
            </div>
            <div className="col-xs-4 col-md-2 mb-3">
              <Label className="form-label">{props.t("Birth Date")}</Label>
              <Input
                id="detailBirthday"
                name="birthday"
                type="date"
                onChange={handleChange}
                value={lead.birthday || ""}
              />
            </div>
            <div className="col-xs-6 col-md-2 mb-3">
              <Label className="form-label">{props.t("Gender")}</Label>
              <Select
                id="detailGender"
                value={gender}
                onChange={(e) => {
                  onChangeInGenderSelect(e);
                }}
                options={props.genderOptions}
                isClearable={true}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Reference")}</Label>
              <Select
                id="detailReference"
                value={reference}
                onChange={(e) => onChangeInReferenceSelect(e)}
                options={props.referenceOptions}
                isClearable={true}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Sales Representative")}</Label>
              <Select
                id="detailRepresent"
                value={salesRepresentative}
                onChange={(e) => {
                  onChangeInRepresentSelect(e);
                }}
                options={props.representOptions}
                isClearable={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Resource")}</Label>
              <Select
                id="detailResource"
                value={resource}
                onChange={(e) => onChangeInResourceSelect(e)}
                options={props.resourceOptions}
                isClearable={true}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Segment")}</Label>
              <Select
                id="detailSegment"
                value={segment}
                onChange={(e) => {
                  onChangeInSegmentSelect(e);
                }}
                options={props.segmentOptions}
                isClearable={true}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Category")}</Label>
              <Select
                id="detailCategory"
                value={category}
                onChange={(e) => {
                  onChangeInCategorySelect(e);
                }}
                options={props.categoryOptions}
                isClearable={true}
              />
            </div>
            <div className="col-xs-6 col-md-3 mb-3">
              <Label className="form-label">{props.t("Form Name")}</Label>
              <Input
                disabled={true}
                id="facebookFormName"
                value={facebookForm ? facebookForm.formName : ""}
              />
            </div>
          </div>
          <div className="text-end mb-3">
            {isAgent && (
              <button
                id="createPatient"
                className="btn btn-warning save-lead"
                onClick={createPatient}
              >
                {props.t("Create Patient")}
              </button>
            )}
            <button
              id="detailSave"
              className="btn btn-success save-lead"
              onClick={updateLead}
            >
              {props.t("Save Changes")}
            </button>
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <LeadNote
            lead={lead}
          />
        </div>
        <hr />
        <Row className="p-2">
          <div className="custom-accordion">
            <Link
              className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
              onClick={toggleAppt}
              to="#"
            >
              <i className="mdi mdi-calendar-account font-size-20 text-secondary"></i>{" "}
              {props.t("Appointments")}
              <i
                className={
                  isOpen
                    ? "mdi mdi-chevron-up accor-down-icon"
                    : "mdi mdi-chevron-down accor-down-icon"
                }
              />
            </Link>
            <Collapse isOpen={apptOpen}>
              <div className="card border-1 shadow-none mb-0">
                {apptOpen ? (
                  <div className="row mt-2">
                    <LeadAppointment
                      lead={lead}
                    />
                  </div>
                ) : null}
              </div>
            </Collapse>
          </div>
        </Row>
        <hr />
        <Row className="p-2">
          <div className="custom-accordion">
            <Link
              className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
              onClick={toggleReminder}
              to="#"
            >
              <i className="mdi mdi-calendar-alert font-size-20 text-secondary"></i>{" "}
              {props.t("Reminders")}
              <i
                className={
                  isOpen
                    ? "mdi mdi-chevron-up accor-down-icon"
                    : "mdi mdi-chevron-down accor-down-icon"
                }
              />
            </Link>
            <Collapse isOpen={reminderOpen}>
              <div className="card border-1 shadow-none mb-0">
                {reminderOpen ? (
                  <div className="row mt-2">
                    <LeadReminder
                      lead={lead}
                    />
                  </div>
                ) : null}
              </div>
            </Collapse>
          </div>
        </Row>
        <hr />
        <Row className="p-2">
          <div className="custom-accordion">
            <Link
              className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
              onClick={toggleOperation}
              to="#"
            >
              <i className="bx bxs-folder-open font-size-20 text-secondary"></i>{" "}
              {props.t("Operations")}
              <i
                className={
                  operationOpen
                    ? "mdi mdi-chevron-up accor-down-icon"
                    : "mdi mdi-chevron-down accor-down-icon"
                }
              />
            </Link>
            <Collapse isOpen={operationOpen}>
              <div className="card border-1 shadow-none m-0">
                {operationOpen ? (
                  <div className="row mt-0">
                    <LeadOperation
                      lead={lead}
                      saveChanges={updateLead}
                    />
                  </div>
                ) : null}
              </div>
            </Collapse>
          </div>
        </Row>
        <hr />
        <Row className="p-2">
          <div className="custom-accordion">
            <Link
              className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
              onClick={toggleOffer}
              to="#"
            >
              <i className="bx bxs-offer font-size-20 text-secondary"></i>{" "}
              {props.t("Offers")}
              <i
                className={
                  offerOpen
                    ? "mdi mdi-chevron-up accor-down-icon"
                    : "mdi mdi-chevron-down accor-down-icon"
                }
              />
            </Link>
            <Collapse isOpen={offerOpen}>
              <div className="card border-1 shadow-none m-0">
                {offerOpen ? (
                  <div className="row mt-0">
                    <LeadOffer
                      lead={lead}
                      saveChanges={updateLead}
                    />
                  </div>
                ) : null}
              </div>
            </Collapse>
          </div>
        </Row>
        <hr />
        <Row className="p-2">
          <div className="custom-accordion">
            <Link
              className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
              onClick={togglePhoto}
              to="#"
            >
              <i className="mdi mdi-filter font-size-20 text-secondary"></i>{" "}
              {props.t("Photos")}
              <i
                className={
                  isOpen
                    ? "mdi mdi-chevron-up accor-down-icon"
                    : "mdi mdi-chevron-down accor-down-icon"
                }
              />
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="card border-1 shadow-none mb-0">
                {isOpen ? (
                  <CustomLightBox
                    custId={lead.id}
                  />
                ) : null}
              </div>
            </Collapse>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
LeadDetail.propTypes = {
  id: PropTypes.any.isRequired,
  t: PropTypes.any,
  lead: PropTypes.any,
  onGetLead: PropTypes.func,
  getLeadNotes: PropTypes.func,
  categoryOptions: PropTypes.any,
  segmentOptions: PropTypes.any,
  resourceOptions: PropTypes.any,
  representOptions: PropTypes.any,
  referenceOptions: PropTypes.any,
  genderOptions: PropTypes.any,
  tabIndex: PropTypes.any
};

export default withRouter(withTranslation()(LeadDetail));
